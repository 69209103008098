import { useRecoilValue } from "recoil";
import { etfSelectedTickerState } from "@states/etf";
import { AssetTitle } from "@pages/asset/styles"

function Header({ name = "name" }) {
  const selectedEtfTicker = useRecoilValue(etfSelectedTickerState);
  return (
    selectedEtfTicker && (
      <>
        <AssetTitle
          ticker={selectedEtfTicker.ticker}
          name={selectedEtfTicker[name]}
        />
      </>
    )
  );
}

export default Header;
