import { MembershipLevel, MembershipType } from "../types/auth";

const activeMembershipLevels = (memberships: Array<MembershipType>): Array<MembershipLevel> => {
  const activeMemberships: Array<MembershipType> = memberships.filter(
    (membership: MembershipType) =>
      new Date(membership.startDate) <= new Date() && new Date(membership.endDate) >= new Date()
  );

  return activeMemberships.map((membership: MembershipType) => membership.level);
}

export function hasActiveProMembership(memberships: Array<MembershipType> | null): boolean {
  if (!memberships)
    return false;

  return activeMembershipLevels(memberships).includes('pro');
}

export function hasActiveEliteMembership(memberships: Array<MembershipType> | null): boolean {
  if (!memberships)
    return false;

  return activeMembershipLevels(memberships).includes('elite');
}