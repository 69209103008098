// routes
import { PATH_BACKTEST, PATH_ETF, PATH_STOCK, } from "../../routes/paths";

// components
import { getIcon } from "@components/asset/SvgIconStyle";

// ----------------------------------------------------------------------


const ICONS = {
  user: getIcon("ic_user"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  stack: getIcon("ic_elements"),
  page: getIcon("ic_page"),
  monitor: getIcon("ic_kanban"),
  calendar: getIcon("ic_calendar"),
  performance: getIcon("ic_dashboard"),
  cart: getIcon("ic_cart"),
  pieChart: getIcon("ic_charts"),
  components: getIcon("ic_components"),
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "데이터",
    items: [
      {
        title: "ETF 데이터",
        path: PATH_ETF.root,
        icon: ICONS.stack,
      },
      {
        title: "주식 데이터",
        path: PATH_STOCK.root,
        icon: ICONS.stack,
      },
      // {
      //   title: "펀드 데이터",
      //   path: PATH_FUND.root,
      //   icon: ICONS.stack,
      //   children: [
      //     { title: "국내", path: PATH_FUND.kr },
      //   ],
      // },
      // {
      //   title: "지수 데이터",
      //   path: PATH_ASSET.index_asset,
      //   icon: ICONS.stack,
      //   children: [
      //     { title: "Global", path: PATH_ASSET_INDEX.global },
      //   ],
      // },
      // {
      //   title: "부동산 데이터",
      //   path: PATH_REALTY.root,
      //   icon: ICONS.stack,
      //   children: [
      //     { title: "지수", path: PATH_REALTY.index },
      //   ],
      // },
    ],
  },
  {
    subheader: "분석",
    items: [
      {
        title: "백테스트",
        path: PATH_BACKTEST.root,
        icon: ICONS.analytics,
        children: [
          { title: "정적 전략 백테스트", path: PATH_BACKTEST.staticBacktest },
        ],
      },
    ]
  },
];

export default sidebarConfig;
