import React, { useState } from "react";

// @material
import {
  Box,
  Stack,
} from "@mui/material";

// components
import AssetTable from "./AssetListViewer/AssetTable";
import EtfHeader from "./Header/Title";
import EtfTabs from "./Tabs";

import Page from "@components/Page";
import { PageTitle } from "@components/ui/styles";
import { SideBar } from "@pages/asset/comparison/SideBar";
import SuspenseContainer from "@components/SuspenseContainer";

// ----------------------------------------------------------------------

function ETFPage() {
  const [checkedAssets, setCheckedAsset] = useState<Array<Object>>([]);
  return (
    <Page title="ETF 종목 탐색">
      <Stack spacing={2}>
        <PageTitle>ETF 종목 탐색</PageTitle>
        <Stack direction="row" sx={{ pl: -10 }}>
          <Box sx={{ width: "100%" }}>
            <Stack spacing={2}>
              <SuspenseContainer>
                <AssetTable
                  onChangeChecked={(e) => {}}
                  onSelectAsset={(select) => {
                    const updateAsset = [select].concat(checkedAssets);
                    setCheckedAsset(updateAsset);
                  }}
                />
              </SuspenseContainer>
              <SuspenseContainer>
                <EtfHeader />
                <EtfTabs />
              </SuspenseContainer>
            </Stack>
          </Box>
        </Stack>
      </Stack>
      <SideBar
        assetType="etf"
        checkedAssets={checkedAssets.map((v) => {
          return { ...v, name: v["name"] };
        })}
        onChange={setCheckedAsset}
      />
    </Page>
  );
}

export default React.memo(ETFPage);