import { useState } from "react";
import { useRecoilValue } from "recoil";
import { Box, Grid, Stack } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";

import { downloadProxyChartExcel } from "@api/asset";
import LoadingButton from "@components/button/LoadingButton";
import PriceLineChart from "@components/chart/PriceLineChart";
import { DateRange, DateRangeSubmitEvent } from "@components/DateRange";
import { authStateAtom } from "@states/auth";
import { AssetType, ProxyChartDataType, UndlInfoType } from "types/common";
import { DateRangeType } from "types/dateRange";
import { createStartAnnotation } from "@utils/chart";
import { Header5Style } from "../styles";


type Props = {
  selectedAsset: AssetType;
  proxyChartData: ProxyChartDataType;
  market: string;
  columns: any[];
  dateRange?: DateRangeType;
  onChangeDateRange?: DateRangeSubmitEvent;
  currency?: string;
  onChangeCurrency?: (string?) => void;
  enableDownload?: boolean
};

export const UndlContent = (
  {
    selectedAsset,
    proxyChartData,
    market,
    columns,
    dateRange,
    onChangeDateRange,
    currency,
    onChangeCurrency,
    enableDownload = true
  }: Props) => {
  const authStates = useRecoilValue(authStateAtom);

  return (
    <Stack spacing={2}>
      <ProxyChart
        proxyChartData={proxyChartData}
        market={market}
        dateRange={dateRange}
        selectedAsset={selectedAsset}
        onChangeDateRange={onChangeDateRange}
        currency={currency}
        onChangeCurrency={onChangeCurrency}
        enableDownload={authStates.hasEliteMembership && enableDownload}
      />
      {authStates.hasEliteMembership && proxyChartData && proxyChartData.undlInfo && (
        <Box sx={{ height: 250, mt: 4 }}>
          <Header5Style>확장 지수에 사용된 기초지수 정보</Header5Style>
          <DataGridPro
            columns={columns}
            rows={proxyChartData.undlInfo}
            disableSelectionOnClick
            density="compact"
            getRowId={(row: UndlInfoType) => row.undlRfId}
          />
        </Box>
      )}
    </Stack>
  );
};

type ProxyChartProps = {
  proxyChartData: ProxyChartDataType;
  selectedAsset: AssetType;
  market: string;
  dateRange?: DateRangeType;
  onChangeDateRange?: DateRangeSubmitEvent;
  currency?: string;
  onChangeCurrency?: (string?) => void;
  enableDownload?: boolean
};

const ProxyChart = (
  {
    proxyChartData,
    selectedAsset,
    market,
    dateRange,
    onChangeDateRange,
    currency,
    onChangeCurrency,
    enableDownload = false
  }: ProxyChartProps) => {
  const [isDailyDownload, setIsDailyDownload] = useState(false);
  const [isWeeklyDownload, setIsWeeklyDownload] = useState(false);
  const [isMonthlyDownload, setIsMonthlyDownload] = useState(false);
  const { baseDates, chartData } = proxyChartData;
  const chartOption = createStartAnnotation(proxyChartData.startDate);

  return (
    <>
      <Grid container spacing={2} justifyContent="flex-end">
        {<Grid item xs={12} md>
          {enableDownload && (
            <Grid container item spacing={1}>
              <Grid item>
                <LoadingButton
                  onClick={async () => {
                    setIsDailyDownload(true);
                    await downloadProxyChartExcel(selectedAsset, "D", currency, dateRange);
                    setIsDailyDownload(false);
                  }}
                  loading={isDailyDownload}
                >
                  일일 데이터 다운로드
                </LoadingButton>
              </Grid>
              <Grid item>
                <LoadingButton
                  onClick={async () => {
                    setIsWeeklyDownload(true);
                    await downloadProxyChartExcel(selectedAsset, "W", currency, dateRange);
                    setIsWeeklyDownload(false);
                  }}
                  loading={isWeeklyDownload}
                >
                  주간 데이터 다운로드
                </LoadingButton>
              </Grid>
              <Grid item>
                <LoadingButton
                  onClick={async () => {
                    setIsMonthlyDownload(true);
                    await downloadProxyChartExcel(selectedAsset, "M", currency, dateRange);
                    setIsMonthlyDownload(false);
                  }}
                  loading={isMonthlyDownload}
                >
                  월간 데이터 다운로드
                </LoadingButton>
              </Grid>
            </Grid>
          )}
        </Grid>}
        <Grid item>
          <DateRange onSubmit={onChangeDateRange} checkValidationBeforeSubmit/>
        </Grid>
      </Grid>
      <Box>
        <PriceLineChart
          market={market}
          index={baseDates}
          data={chartData}
          currency={currency}
          setCurrency={onChangeCurrency}
          options={chartOption}
          height={400}
        />
      </Box>
    </>
  );
};
