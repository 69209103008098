import { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Box } from "@mui/material";
import { assetCompareAssetIds, assetInformationState } from "@states/asset";
import TreeMapChart from "@components/chart/TreeMapChart";

export default function TreeMap({ assetIds }) {
  const setAssetCompareAssetIds = useSetRecoilState(assetCompareAssetIds);

  useEffect(() => {
    setAssetCompareAssetIds(assetIds);
  }, [assetIds, setAssetCompareAssetIds]);

  const gridData = useRecoilValue(assetInformationState);

  if (!gridData || !gridData.length) {
    return <></>;
  }

  const chartData = gridData.map((data) => (
    {
      x: data["name"],
      y: data["averageVolume"],
    }
  ));

  return (
    <Box sx={{ pt: 2 }}>
      {chartData.length > 0 && (
        <TreeMapChart title="최근 3개월 평균 거래량" data={chartData}/>
      )}
    </Box>
  );
}
