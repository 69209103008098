import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";

import AssetInformationGrid from "@components/grid/AssetInformationGrid";
import { ETF_LISTING_COLUMNS } from "@pages/asset/define";
import { Toolbar } from '@pages/asset/detail/Toolbar';
import { etfListingState, etfQueryFilterState, etfSelectedTickerState } from "@states/etf";
import useAuth from "@hooks/useAuth";
import useSearchHook from "@hooks/useSearchHook";

function EtfTable({ onChangeChecked, onSelectAsset }) {
  const setSelectedTicker = useSetRecoilState(etfSelectedTickerState);
  const etfListing = useRecoilValue(etfListingState);
  const [etfCountryFilter, setEtfCountryFilter] = useRecoilState(etfQueryFilterState);
  const { hasProMembership } = useAuth();

  const [searchText, setSearch] = useState("");
  const [gridData, setGridData] = useState(etfListing);

  const [updateSearch] = useSearchHook(
    etfListing,
    setSearch,
    setGridData
  );

  useEffect(() => {
    setGridData(etfListing);
  }, [etfListing])

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newCountry: string,
  ) => {
    if (newCountry) {
      setEtfCountryFilter(newCountry);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        // justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} md={6}>
          <Toolbar text={searchText} onChangeText={updateSearch}/>
        </Grid>
        <Grid item xs={12} md={2}>
          {(hasProMembership) ?
            (<ToggleButtonGroup
              color="primary"
              value={etfCountryFilter}
              exclusive
              size="small"
              onChange={handleChange}
              aria-label="Platform"
            >
              <ToggleButton value="ALL">전체</ToggleButton>
              <ToggleButton value="KR">한국</ToggleButton>
              <ToggleButton value="US">미국</ToggleButton>
            </ToggleButtonGroup>) : null}
        </Grid>
      </Grid>
      <AssetInformationGrid
        market={"ETF"}
        onSelectAsset={(asset) => {
          setSelectedTicker(asset);
          if (onSelectAsset) {
            onSelectAsset(asset);
          }
        }}
        columns={ETF_LISTING_COLUMNS}
        data={gridData}
        onChangeChecked={onChangeChecked}
      />
    </>
  );
}

export default EtfTable;
