import { useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Box, Grid } from "@mui/material";

import { downloadProxyChartExcel } from "@api/asset";
import { DateRange } from "@components/DateRange";
import PriceLineChart from "@components/chart/PriceLineChart";
import LoadingButton from "@components/button/LoadingButton";
import { useOpenSnackbar } from "@hooks/useSnackbar";
import { authStateAtom } from "@states/auth";
import {
  stockChartDataState,
  stockSelectedTickerState,
  stockChartDateRangeState,
  stockChartCurrencyState,
} from "@states/stock";

function DetailChartPanel() {
  const authStates = useRecoilValue(authStateAtom);
  const stockChartData = useRecoilValue(stockChartDataState);
  const selectedAsset = useRecoilValue(stockSelectedTickerState);
  const [chartDateRange, setChartDateRange] = useRecoilState(stockChartDateRangeState);
  const [chartCurrency, setChartCurrency] = useRecoilState(stockChartCurrencyState);

  const [isDailyDownload, setIsDailyDownload] = useState(false);
  const [isWeeklyDownload, setIsWeeklyDownload] = useState(false);
  const [isMonthlyDownload, setIsMonthlyDownload] = useState(false);


  const market = useMemo(() => {
    if (selectedAsset?.assetId.startsWith("SU"))
      return"us"
    else
      return "kr"
  }, [selectedAsset]);

  useEffect(()=> {
    if (market === "us")
      setChartCurrency("USD");
    else
      setChartCurrency("KRW");
  }, [market, setChartCurrency]);

  const openSnackbar = useOpenSnackbar();

  useEffect(() => {
    if (!stockChartData) {
      openSnackbar("데이터가 없습니다. 😰", "error");
    } else return;
  }, [stockChartData, openSnackbar]);

  if (!selectedAsset || !stockChartData)
    return <></>;

  const { baseDates, chartData } = stockChartData;

  const options = {
    chart: {
      id: "chart#1",
      animations: {
        enabled: false,
      },
      toolbar: {
        show: true,
        tools:{
          download: false
        }
      },
      zoom: {
        autoScaleYaxis: true,
      },
      sparkline: {
        enabled: false,
      },
      selection: {
        enabled: false,
        // xaxis: {
        //   min: Math.max(new Date(baseDates[0]).getTime(), new Date(`${CURRENT_YEAR}.01.01`).getTime()),
        //   max: new Date(baseDates[baseDates.length - 1]).getTime(),
        // },
      },
    },
    stroke: {
      width: 2,
    },
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="flex-end">
        {<Grid item xs={12} md>
          {authStates.hasEliteMembership && (
            <Grid container item spacing={1}>
              <Grid item>
                <LoadingButton
                  onClick={async () => {
                    setIsDailyDownload(true);
                    await downloadProxyChartExcel(selectedAsset, "D", chartCurrency, chartDateRange);
                    setIsDailyDownload(false);
                  }}
                  loading={isDailyDownload}
                >
                  일일 데이터 다운로드
                </LoadingButton>
              </Grid>
              <Grid item>
                <LoadingButton
                  onClick={async () => {
                    setIsWeeklyDownload(true);
                    await downloadProxyChartExcel(selectedAsset, "W", chartCurrency, chartDateRange);
                    setIsWeeklyDownload(false);
                  }}
                  loading={isWeeklyDownload}
                >
                  주간 데이터 다운로드
                </LoadingButton>
              </Grid>
              <Grid item>
                <LoadingButton
                  onClick={async () => {
                    setIsMonthlyDownload(true);
                    await downloadProxyChartExcel(selectedAsset, "M", chartCurrency, chartDateRange);
                    setIsMonthlyDownload(false);
                  }}
                  loading={isMonthlyDownload}
                >
                  월간 데이터 다운로드
                </LoadingButton>
              </Grid>
            </Grid>
          )}
        </Grid>}
        <Grid item>
          <DateRange onSubmit={setChartDateRange} checkValidationBeforeSubmit/>
        </Grid>
      </Grid>
      <Box>
        <PriceLineChart
          market={market}
          index={baseDates}
          data={chartData}
          currency={chartCurrency}
          setCurrency={setChartCurrency}
          options={options}
          // showNavigator
          height={400}
        />
      </Box>
    </>
  );
}

export default DetailChartPanel;
