import { Navigate, useRoutes } from "react-router-dom";

// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";

// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";

// Auth
import Login from "@pages/auth/Login";

// ETF
import ETFPage from "@pages/asset/etf/all";

// Stock
import StockPage from "@pages/asset/stock/all";

// Backtest
import StaticBacktest from "@pages/backtest/StaticBacktest";

// User
import User from "@pages/user";

// ETC
import NotFound from "@pages/Page404";
import Page500 from "@components/error/Page500";
import SuspenseContainer from "@components/SuspenseContainer";

function Router() {
  const routes = useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login/>
            </GuestGuard>
          ),
        },
        { path: "login-unprotected", element: <Login/> },
      ],
    },
    {
      path: "/",
      element: (
        <AuthGuard>
          <DashboardLayout/>
        </AuthGuard>
      ),
      children: [
        {
          path: "/",
          element: <Navigate replace to="/etf"/>
        },
        {
          path: "etf",
          element: (
            <SuspenseContainer>
              <ETFPage/>
            </SuspenseContainer>
          ),
        },
        {
          path: "stock",
          element: (
            <SuspenseContainer>
              <StockPage/>
            </SuspenseContainer>
          ),
        },
        {
          path: "backtest/static",
          element: <StaticBacktest/>
        },
        {
          path: "user",
          element: (
            <SuspenseContainer>
              <User/>
            </SuspenseContainer>
          ),
        },
      ],
    },
    {
      path: "error",
      element: <LogoOnlyLayout/>,
      children: [{ path: "500", element: <Page500/> }],
    },
    {
      path: "*",
      element: <LogoOnlyLayout/>,
      children: [
        { path: "404", element: <NotFound/> },
        { path: "*", element: <Navigate to="/404" replace/> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace/>
    },
  ]);

  return routes;
}

export default Router;
