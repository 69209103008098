import { useRecoilValue } from "recoil";
import { Box } from "@mui/material";

import TimeSeriesChart from "@components/chart/TimeSeriesChart";
import { assetCompareDrawDownState } from "@states/asset";

export default function DrawDown() {
  const data = useRecoilValue(assetCompareDrawDownState);

  if (!data) {
    return <></>;
  }

  return (
    <Box sx={{ height: 450 }}>
      <TimeSeriesChart
        height={400}
        data={data}
        extraChartOptions={{
          theme: {
            mode: "light",
            palette: "palette2",
            monochrome: {
              enabled: false,
              color: "#255aee",
              shadeTo: "light",
              shadeIntensity: 0.65,
            },
          },
          yaxis: {
            max: 0,
            tickAmount: 5,
            labels: {
              formatter: (v) =>
                typeof v === "number" ? (v * 100).toFixed() + "%" : v,
            },
          },
        }}
      />
    </Box>
  );
}
