import { useRecoilState } from "recoil";
import moment from "moment";
import { Button, TextField, Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { useOpenSnackbar } from "@hooks/useSnackbar";
import { dateRangeBeginState, dateRangeEndState } from "@states/asset";
import { DateRangeType } from '../types/dateRange';

export type DateRangeSubmitEvent = (dateRange: DateRangeType) => void;

export type DateRangeProps = {
  onSubmit?: DateRangeSubmitEvent;
  checkValidationBeforeSubmit?: boolean;
};

export const DateRange = (props: DateRangeProps) => {
  const [begin, setBegin] = useRecoilState(dateRangeBeginState);
  const [end, setEnd] = useRecoilState(dateRangeEndState);

  const openSnackbar = useOpenSnackbar();

  const checkDateRangeValidation = (begin, end) => {
    if (begin) {
      if (!moment(begin, "YYYY-MM-DD", true).isValid()) {
        openSnackbar("시작 날짜 형식을 올바르게 입력해주세요.", "warning");
        return false;
      }
      if (begin > moment().format("YYYY-MM-DD")) {
        openSnackbar("시작 날짜를 과거 날짜로 입력해주세요.", "warning");
        return false;
      }
    }
    if (end) {
      if (!moment(end, "YYYY-MM-DD", true).isValid()) {
        openSnackbar("종료 날짜 형식을 올바르게 입력해주세요.", "warning");
        return false;
      }
      if (end > moment().format("YYYY-MM-DD")) {
        openSnackbar("종료 날짜를 과거 날짜로 입력해주세요.", "warning");
        return false;
      }
    }
    if (begin && end && begin >= end) {
      openSnackbar("시작 날짜를 종료 날짜보다 과거 날짜로로 입력해주세요.", "warning");
      return false;
    }
    return true;
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={5} md={5}>
        <TextField
          size="small"
          type="date"
          value={begin}
          onChange={(e) => {
            if(e.target.value===""){
              setBegin(null)  
            }else{
              setBegin(e.target.value);
            }
          }}
          sx={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={5} md={5}>
        <TextField
          size="small"
          type="date"
          value={end}
          onChange={(e) => {
            if(e.target.value===""){
              setEnd(null);  
            }else{
              setEnd(e.target.value);
            }
          }}
          sx={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={2} md={2}>
        <Button
          variant="outlined"
          size="small"
          style={{ width: "100%", minWidth: 45, height: 40 }}
          onClick={(e) => {
            if (props.onSubmit) {
              if (props.checkValidationBeforeSubmit && !checkDateRangeValidation(begin, end))
                return;
              props.onSubmit({ begin, end });
            }
          }}
        >
          <SearchIcon />
        </Button>
      </Grid>
    </Grid>
  );
};

export default DateRange;