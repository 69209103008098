import producer from "immer";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { toSafeInteger } from "lodash";

import { staticBacktestConfigState, staticBacktestStrategiesState } from "@states/staticBacktest";
import { InputAdornment, MenuItem, Select, Stack, TextField, } from "@mui/material";
import { StyledLabel } from "@components/ui/styles";
import { IBacktestCashflow } from "types/backtest";
import React from "react";

export default function BacktestOption({ strategy, strategyIndex }) {
  const setStrategies = useSetRecoilState(staticBacktestStrategiesState);
  const backtestConfig = useRecoilValue(staticBacktestConfigState);

  const cashflow: IBacktestCashflow = (strategy.cashflows) ? strategy.cashflows[0] : null;

  // const handleChange = (name) => (e) => {
  //   setBacktestConfig({ ...backtestConfig, [name]: e.target.value });
  // };
  // const handleChangeConfig = (name, value) => {
  //   const newValue = { ...backtestConfig, [name]: value };
  //   setBacktestConfig(newValue);
  // };

  return (
    <Stack spacing={2}>
      <Stack spacing={2}>
        <StyledLabel>리밸런싱 방식</StyledLabel>
        <Select
          sx={{ width: "200px" }}
          labelId="rebal-type-select-label"
          id="rebal-type-select"
          size="small"
          value={strategy.rebal_method}
          onChange={(e) => {
            setStrategies((prevState) =>
              producer(prevState, (draft) => {
                draft[strategyIndex]["rebal_method"] = e.target.value;
                if (draft[strategyIndex]["rebal_method"] === "Dynamic") {
                  draft[strategyIndex]["threshold"] = 8;
                }
                if (draft[strategyIndex]["rebal_method"] === "Static") {
                  draft[strategyIndex]["rebal_freq"] = "M";
                }
              })
            );
          }}
        >
          <MenuItem value="Static">정기</MenuItem>
          <MenuItem value="Dynamic">수시</MenuItem>
          <MenuItem value="None">없음</MenuItem>
        </Select>
        {strategy.rebal_method === "Static" && (
          <Select
            sx={{ width: "200px" }}
            labelId="rebal-type-select-label"
            id="rebal-type-select"
            size="small"
            value={strategy.rebal_freq}
            onChange={(e) =>
              setStrategies((prevState) =>
                producer(prevState, (draft) => {
                  draft[strategyIndex]["rebal_freq"] = e.target.value;
                })
              )
            }
          >
            <MenuItem value="M">월</MenuItem>
            <MenuItem value="Q">분기</MenuItem>
            <MenuItem value="Y">년</MenuItem>
          </Select>
        )}
        {strategy.rebal_method === "Dynamic" && (
          <>
            <StyledLabel>임계치</StyledLabel>
            <TextField
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              value={strategy.threshold}
              size="small"
              type="number"
              sx={{ width: "100%" }}
              onChange={(e) =>
                setStrategies((prevState) =>
                  producer(prevState, (draft) => {
                    if (!e.target.value) {
                      draft[strategyIndex]["threshold"] = undefined;
                    } else {
                      draft[strategyIndex]["threshold"] = toSafeInteger(e.target.value);
                    }
                  })
                )
              }
            />
          </>
        )}
      </Stack>
      <Stack spacing={2}>
        <StyledLabel>추가 입출금</StyledLabel>
        <Select
          sx={{ width: "200px" }}
          labelId="cashflow-type-select-label"
          id="rebal-type-select"
          size="small"
          value={(cashflow && cashflow.method) || "None"}
          onChange={(e) => {
            if (e.target.value === "None") {
              setStrategies((prevState) => producer(prevState, (draft) => {
                draft[strategyIndex]["cashflows"] = undefined;
              }))
            } else if (e.target.value === "FixedAmountContribution") {
              setStrategies((prevState) =>
                producer(prevState, (draft) => {
                  draft[strategyIndex]["cashflows"] = [{
                    "method": "FixedAmountContribution",
                    "amount": 0,
                    "freq": "M",
                  }];
                })
              );
            }
          }}
        >
          <MenuItem value="FixedAmountContribution">정기 입금</MenuItem>
          <MenuItem value="None">없음</MenuItem>
        </Select>
        {cashflow && cashflow.method === "FixedAmountContribution" && (
          <TextField
            InputProps={{
              endAdornment: <InputAdornment
                position="end">{backtestConfig.currency == "KRW" ? "만원" : "$"}</InputAdornment>,
            }}
            value={cashflow.amount}
            label="정기 입금 금액"
            size="small"
            type="number"
            sx={{ width: "100%" }}
            onChange={(e) => {
              setStrategies((prevState) =>
                producer(prevState, (draft) => {
                  if (draft[strategyIndex]["cashflows"]) {
                    draft[strategyIndex]["cashflows"] = [{
                      "method": cashflow["method"],
                      "amount": parseInt(e.target.value),
                      "freq": cashflow["freq"],
                    }];
                  }
                }))
            }}
          />
        )}
        {cashflow && cashflow.method === "FixedAmountContribution" && (
          <Select
            sx={{ width: "200px" }}
            labelId="cashflow-freq-select-label"
            id="cashflow-freq-select"
            size="small"
            value={cashflow && cashflow.freq}
            onChange={(e) => {
              if (cashflow) {
                setStrategies((prevState) =>
                  producer(prevState, (draft) => {

                    // draft[strategyIndex]["cashflows"] .push({
                    //   "method": draft[strategyIndex]["cashflows"][0]["method"]
                    // })

                    if (draft[strategyIndex]["cashflows"]) {
                      draft[strategyIndex]["cashflows"] = [{
                        "method": cashflow["method"],
                        "amount": cashflow["amount"],
                        "freq": e.target.value,
                      }];
                      // draft[strategyIndex]["cashflows"][0]["freq"] = "M";
                      // draft[strategyIndex]["cashflows"][0]["method"] = e.target.value;
                    }
                  }))
              }
            }}
          >
            <MenuItem value="M">월</MenuItem>
            <MenuItem value="Q">분기</MenuItem>
            <MenuItem value="Y">년</MenuItem>
          </Select>
        )}
      </Stack>
    </Stack>
  )
    ;
}
