import { Suspense } from "react";
import { Stack, Box } from "@mui/material";
import { PageTitle } from "@components/ui/styles";
import { useRecoilValue } from "recoil";
import Page from "@components/Page";
import {staticBacktestLoadingState, staticBacktestBacktestDetailState} from "@states/staticBacktest";
import LoadingScreen from "@components/LoadingScreen";
import BacktestRunForm from "./RunForm";
import DataTabs from "./DataTabs";

export default function StaticBacktest() {
  const loading = useRecoilValue(staticBacktestLoadingState);
  const backtestDetail = useRecoilValue(staticBacktestBacktestDetailState);
  return (
    <Page title="정적 전략 백테스트">
      <Stack spacing={2}>
        <PageTitle>정적 전략 백테스트</PageTitle>
        <Suspense fallback={<LoadingScreen />}>
          <BacktestRunForm />
          {loading ? (
            <Box sx={{ height: "200px" }}>
              <LoadingScreen />
            </Box>
          ) : backtestDetail ? (
            <DataTabs />
          ) : (
            <div></div>
          )}
        </Suspense>
      </Stack>
    </Page>
  );
}
