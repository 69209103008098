import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";

import AssetInformationGrid from "@components/grid/AssetInformationGrid";
import { STOCK_LISTING_COLUMNS } from "@pages/asset/define";
import { Toolbar } from "@pages/asset/detail/Toolbar";
import { stockListingState, stockQueryFilterState, stockSelectedTickerState } from "@states/stock";
import useSearchHook from "@hooks/useSearchHook";
import useAuth from "@hooks/useAuth";

type Props = {
  onChangeChecked?: (e) => void;
  onSelectAsset?: Function;
};

function OperationTable({ onChangeChecked, onSelectAsset }: Props) {
  const setSelectedTicker = useSetRecoilState(stockSelectedTickerState);
  const stockListing = useRecoilValue(stockListingState);
  const [stockCountryFilter, setStockCountryFilter] = useRecoilState(stockQueryFilterState);
  const { hasProMembership } = useAuth();

  const [searchText, setSearch] = useState("");
  const [gridData, setGridData] = useState(stockListing);

  const [updateSearch] = useSearchHook(
    stockListing,
    setSearch,
    setGridData
  );

  useEffect(() => {
    setGridData(stockListing)
  }, [stockListing])

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newCountry: string,
  ) => {
    if (newCountry) {
      setStockCountryFilter(newCountry);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
      >
        <Grid item xs={12} md={6}>
          <Toolbar text={searchText} onChangeText={updateSearch}/>
        </Grid>
        <Grid item xs={12} md={2}>
          {(hasProMembership) ?
            (<ToggleButtonGroup
              color="primary"
              value={stockCountryFilter}
              exclusive
              size="small"
              onChange={handleChange}
              aria-label="Platform"
            >
              <ToggleButton value="ALL">전체</ToggleButton>
              <ToggleButton value="KR">한국</ToggleButton>
              <ToggleButton value="US">미국</ToggleButton>
            </ToggleButtonGroup>) : null}
        </Grid>
      </Grid>
      <AssetInformationGrid
        market={"STOCK"}
        onSelectAsset={(asset) => {
          setSelectedTicker(asset);
          if (onSelectAsset) {
            onSelectAsset(asset);
          }
        }}
        columns={STOCK_LISTING_COLUMNS}
        data={gridData}
        onChangeChecked={onChangeChecked}
      />
    </>
  );
}

export default OperationTable;
