import React, { useRef, useState } from "react";

import { Typography, Stack,Paper } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import axiosInstance from "@utils/axios";
import { useOpenSnackbar } from "@hooks/useSnackbar";

function ChangePwPanel() {
  const newPwRef = useRef<HTMLInputElement>(null);
  const secondNewPwRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState("");

  const openSnackbar = useOpenSnackbar();

  const postChangePassword = async (newPassword) => {
    try {
      await axiosInstance.patch("users/me", {
        password: newPassword,
      });
      openSnackbar("비밀번호가 변경되었습니다.", "success");
      setError("");
    } catch (e) {
      setError("비밀번호 변경에 실패하였습니다.")
      // setError("현재 비밀번호가 맞지 않습니다.");
    }
  };

  const isValidation = (newPassword1, newPassword2) => {
    if (newPassword1 !== newPassword2) {
      setError("신규 비밀번호가 일치하지 않습니다.");
      return false;
    }

    const regExp = /^(?=.*\d)(?=.*[A-Za-z])[A-Za-z\d@$!%*#?&]{8,}$/;
    if (!regExp.test(newPassword1)) {
      setError(
        "비밀번호는 최소 8자 이상, 영문자 및 숫자를 하나이상 포함하여야 합니다."
      );
      return false;
    }
    return true;
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      isValidation(
        newPwRef.current?.value,
        secondNewPwRef.current?.value
      )
    ) {
      postChangePassword(secondNewPwRef.current?.value);
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 500 }}>
    <Stack
      component="form"
      sx={{ maxWidth: 500 }}
      spacing={2}
      onSubmit={handleSubmit}
    >
      <TextField
        inputRef={newPwRef}
        id="new_password_one"
        label="새 비밀번호"
        name="new_password_one"
        type="password"
        required
        fullWidth
        margin="normal"
      />
      <TextField
        inputRef={secondNewPwRef}
        id="new_password_two"
        label="새 비밀번호 확인"
        name="new_password_two"
        type="password"
        required
        fullWidth
        margin="normal"
      />
      <Typography style={{ color: "red", fontWeight: "bold" }}>
        {error}
      </Typography>
      <Button
        type="submit"
        variant="contained"
        style={{
          fontSize: "18px",
          fontWeight: "700",
        }}
        fullWidth
      >
        확인
      </Button>
    </Stack>
    </Paper>
  );
}

export default ChangePwPanel;
