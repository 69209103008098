import ReactApexChart from "react-apexcharts";
// material
import { Card, CardHeader } from "@mui/material";
import { ApexOptions } from "apexcharts";

export default function HeatmapChart({ title, data }) {
  const chartOption: ApexOptions = {
    chart: {
      toolbar: {
        show: true,
        tools:{
          download: false
        }
      },
      width: "400px",
    },
    plotOptions: {
      heatmap: {
        enableShades: true,
        shadeIntensity: 0.4,
        reverseNegativeShade: true,
        radius: 0,
        colorScale: {
          ranges: [
            {
              from: -1,
              to: -0,
              color: "#ff0000",
            },
            {
              from: 0,
              to: 1,
              color: "#0d306b",
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val: number, opts) => val.toFixed(3),
    },
    stroke: {
      width: 1,
    },
    yaxis: {
      opposite: true,
      reversed: false,
      labels: {
        formatter: (val: any) => {
          return val;
        },
      },
    },
    xaxis: {
      position: "bottom",
      labels: {
        rotate: 0,
        hideOverlappingLabels: false,
        trim: true,
      },
      tooltip: {
        enabled: true,
      },
    },
  };


  return (
    <Card sx={{ px: 4, p: 2, pb: 2, textAlign: "center" }}>
      <CardHeader title={title} />
      <ReactApexChart
        type="heatmap"
        series={data}
        options={chartOption}
        height={450}
        width={"100%"}
      />
    </Card>
  );
}
