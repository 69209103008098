import { atom, selector } from "recoil";
import axios from "@utils/axios";
import { AssetType, ProxyChartDataType } from "types/common";
import { DateRangeType } from "types/dateRange";


export const valueState = atom({
  key: "etfValueState",
  default: 0,
});

export const etfChartDateRangeState = atom<DateRangeType>({
  key: "etfChartDateRangeState",
  default: {
    begin: null,
    end: null
  },
});

export const etfChartCurrencyState = atom({
  key: "etfChartCurrencyState",
  default: "KRW",
})

export const etfSelectedTickerState = atom<AssetType | null>({
  key: "etfSelectedTickerState",
  default: null,
});

export const etfQueryFilterState = atom<any>({
  key: "etfQueryFilterState",
  default: "KR"
});

export const etfListingState = selector<AssetType[]>({
  key: "etfListingState",
  get: async ({ get }) => {
    const countryFilter = get(etfQueryFilterState);
    const params = {
      "assetType": "ETF",
    };
    if (countryFilter) {
      if (countryFilter !== 'ALL') {
        params['country'] = countryFilter
      }
    }
    ;
    const response = await axios.get(`assets`, {
      params: params
    });

    return response.data;
  },
});

export const etfAnalysisCurrencyState = atom({
  key: "etfAnalysisCurrencyState",
  default: "KRW",
})

export const analysisDataState = atom({
  key: "etfAnalysisDataState",
  default: { load: false }
});


export const etfChartDataState = selector<ProxyChartDataType | null>({
  key: "etfChartDataState",
  get: async ({ get }) => {
    const selected = get(etfSelectedTickerState);
    if (selected === null)
      return;
    const { assetId } = selected;
    const dataParam = get(etfChartDateRangeState);
    const currency = get(etfChartCurrencyState);
    const params = {
      "begin": dataParam.begin,
      "end": dataParam.end,
      "currency": currency,
    }

    const response = await axios.get(
      `bts/assets/${assetId}/proxy`,
      { params: params }
    );

    return response.data;
  }
});
