import React, { useMemo, useState } from "react";
import _ from "lodash";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useRecoilRefresher_UNSTABLE, useRecoilState, useSetRecoilState } from "recoil";
import {
  staticBacktestConfigState,
  staticBacktestHistoriesQuery,
  staticBacktestInitialConfig,
  staticBacktestOverviewState,
  staticBacktestStrategiesState,
} from "@states/staticBacktest";
import { StyledCard } from "@components/ui/styles";
import BacktestHistoryDialog from "@pages/backtest/StaticBacktest/RunForm/BacktestHistoryDialog";
import { STATIC_STRATEGY_DEFAULT } from "@pages/backtest/define";
import { IBacktestStaticConfig } from "types/backtest";
import useAuth from "@hooks/useAuth";

const benchmarkInfoList = [
  { bm_name: "KOSPI200 TR", bm_ticker: "LN-0009", currency: "KRW" },
  { bm_name: "S&P500 TR", bm_ticker: "LN-0006", currency: "USD" },
  // { bm_name: "S&P500 TR(UH)", bm_ticker: "LN-0007", currency: "KRW" },
];

function BacktestNav() {
  const [backtestConfig, setBacktestConfig] = useRecoilState(staticBacktestConfigState);
  const setStrategies = useSetRecoilState(staticBacktestStrategiesState);
  const setOverview = useSetRecoilState(staticBacktestOverviewState);
  const backtestHistoriesRefresher = useRecoilRefresher_UNSTABLE(staticBacktestHistoriesQuery);

  const { hasProMembership } = useAuth();

  const [isHistoryDialogOpen, setIsHistoryDialogOpen] = useState<boolean>(false);

  const handleChange = (name) => (e) => {
    setBacktestConfig({ ...backtestConfig, [name]: e.target.value });
  };
  const handleChangeConfig = (name, value) => {
    const newValue = { ...backtestConfig, [name]: value };
    setBacktestConfig(newValue);
  };

  const benchMarkValue = useMemo(() => {
    return benchmarkInfoList.filter((v) =>
      backtestConfig.benchmark.includes(v.bm_name)
    );
  }, [backtestConfig.benchmark]);

  const handleReset = () => {
    if (window.confirm("전략을 리셋 하시겠습니까??")) {
      setBacktestConfig(staticBacktestInitialConfig);
      setStrategies([_.cloneDeep(STATIC_STRATEGY_DEFAULT)]);
      setOverview([]);
    } else {
    }
  };

  const setBacktestConfigAndStrategies = (config: IBacktestStaticConfig, version?: string) => {
    const _config = { ...config };

    _config["cost"] = _config["cost"] * 100;
    if (!_config["initial_amount"]) {
      _config["initial_amount"] = 100;
    }

    setStrategies(_config.strategies || []);
    setBacktestConfig(_config);
  };

  return (
    <StyledCard>
      <Grid container spacing={1}>
        <Grid container item xs={12} md={9} spacing={1}>
          <Grid container item spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField
                label="백테스트 이름"
                size="small"
                type="text"
                sx={{ width: "100%" }}
                value={backtestConfig.name}
                onChange={(e) => handleChangeConfig("name", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Tooltip
                title="분석 주기"
                arrow
                placement="top"
              >
                <ToggleButtonGroup
                  color="primary"
                  value={backtestConfig.data_freq}
                  exclusive
                  size="small"
                  fullWidth
                  onChange={(e, v) => {
                    if (v !== null) {
                      handleChangeConfig("data_freq", v);
                    }
                  }}
                >
                  <ToggleButton value="D">일별</ToggleButton>
                  <ToggleButton value="M">월별</ToggleButton>
                </ToggleButtonGroup>
              </Tooltip>
            </Grid>
            {(hasProMembership) ?
              <Grid item xs={12} md={2}>
                <Tooltip
                  title="분석 통화"
                  arrow
                  placement="top"
                >
                  <ToggleButtonGroup
                    color="primary"
                    value={backtestConfig.currency}
                    exclusive
                    size="small"
                    fullWidth
                    onChange={(e, v) => {
                      if (v !== null) {
                        handleChangeConfig("currency", v);
                      }
                    }}
                  >
                    <ToggleButton value="KRW">원</ToggleButton>
                    <ToggleButton value="USD">달러</ToggleButton>
                  </ToggleButtonGroup>
                </Tooltip>
              </Grid> : null
            }
          </Grid>
          <Grid container item spacing={1}>
            <Grid item xs={12} md={3}>
              <TextField
                label="시작 날짜 (yyyymmdd)"
                size="small"
                type="text"
                sx={{ width: "100%" }}
                value={backtestConfig.bt_start}
                onChange={(e) => {
                  handleChangeConfig("bt_start", e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="종료 날짜 (yyyymmdd)"
                size="small"
                type="text"
                sx={{ width: "100%" }}
                value={backtestConfig.bt_end}
                onChange={(e) => {
                  handleChangeConfig("bt_end", e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                InputProps={{
                  endAdornment: <InputAdornment
                    position="end">{backtestConfig.currency == "KRW" ? "만원" : "$"}</InputAdornment>,
                }}
                value={backtestConfig.initial_amount}
                label="초기 금액"
                size="small"
                type="number"
                sx={{ width: "100%" }}
                onChange={handleChange("initial_amount")}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                value={backtestConfig.cost}
                label="거래 비용"
                size="small"
                type="number"
                sx={{ width: "100%" }}
                onChange={handleChange("cost")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                size="small"
                fullWidth
                value={benchMarkValue}
                options={benchmarkInfoList}
                getOptionLabel={(option) => option.bm_name}
                renderInput={(params) => (
                  <TextField {...params} label="벤치마크 지수"/>
                )}
                onChange={(e, v) => {
                  handleChangeConfig(
                    "benchmark",
                    v.map((v) => v.bm_name)
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={3} spacing={1}>
          <Grid item xs={12} md={12}>
            <Stack spacing={2} justifyContent={"flex-end"} direction={"row"}>
              <Button
                variant="contained"
                onClick={() => {
                  setIsHistoryDialogOpen(true);
                  backtestHistoriesRefresher();
                }}
                size="medium"
              >
                백테스트 로드
              </Button>
              <IconButton onClick={handleReset}>
                <RefreshIcon/>
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Box>
        <BacktestHistoryDialog
          open={isHistoryDialogOpen}
          onSelect={(config: IBacktestStaticConfig) => {
            setIsHistoryDialogOpen(false);
            setBacktestConfigAndStrategies(config);
          }}
          onClose={() => {
            setIsHistoryDialogOpen(false);
          }}
        />
      </Box>
    </StyledCard>
  );
}

export default BacktestNav;
