import { useEffect, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { analysisDataState, etfAnalysisCurrencyState, etfSelectedTickerState, } from "@states/etf";
import AnalysisDetail from "@pages/asset/detail/AnalysisDetail";
import { DateRange } from "@components/DateRange";
import { getAnalysis } from "@api/asset";
import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";

function AnalysisPanel() {
  const selectedAsset = useRecoilValue(etfSelectedTickerState);
  const [analysisData, setAnalysisData] = useRecoilState(analysisDataState);
  const [analysisCurrency, setAnalysisCurrency] = useRecoilState(etfAnalysisCurrencyState);

  const market = useMemo(() => {
    if (selectedAsset?.assetId?.startsWith("EU"))
      return "us"
    else
      return "kr"
  }, [selectedAsset]);

  useEffect(() => {
    setAnalysisData({ load: false });
  }, [selectedAsset, setAnalysisData]);

  useEffect(() => {
    if (market === "us")
      setAnalysisCurrency("USD");
    else
      setAnalysisCurrency("KRW");
  }, [market, setAnalysisCurrency]);

  const handleSubmit = async (date) => {
    if (selectedAsset?.assetId) {
      const data = await getAnalysis(
        selectedAsset?.assetId,
        date.begin,
        date.end,
        analysisCurrency,
      );
      setAnalysisData(data);
    }
  };

  return (
    <>
      <Grid container justifyContent={"flex-end"} spacing={2}>
        {market === "us" && <Grid item>
          <FormGroup sx={{ marginLeft: "5px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="info"
                  checked={analysisCurrency === "KRW"}
                  onChange={(e) => {
                    if (e.target.checked)
                      setAnalysisCurrency("KRW");
                    else
                      setAnalysisCurrency("USD");
                  }}
                />
              }
              label={"원화 기준"}
            />
          </FormGroup>
        </Grid>}
        <Grid item>
          <DateRange onSubmit={handleSubmit} checkValidationBeforeSubmit/>
        </Grid>
      </Grid>
      <AnalysisDetail
        ticker={selectedAsset?.ticker}
        analysisData={analysisData}
      />
    </>
  );
}

export default AnalysisPanel;
