import { useEffect, useMemo, useRef, useState } from "react";
import { ApexOptions } from "apexcharts";
import { cloneDeep } from "lodash";
import ReactApexChart from "react-apexcharts";
import { useRecoilState, useSetRecoilState } from "recoil";

// material
import { PERIOD_TIME_MAP } from "@components/tab/PeriodTab/define";
import { Box, CardHeader, Checkbox, FormControlLabel, FormGroup, Grid, Stack, Typography, } from "@mui/material";
import { dateRangeBeginState, dateRangeEndState, logToggleState, } from "@states/asset";
import { ChartNavigator } from "./ChartNavigator";
import PriceLogChart from "./PriceLogChart";
// ----------------------------------------------------------------------

export type PriceLineChartProps = {
  title?: string;
  index?: any;
  data: any;
  options?: any;
  market?: string;
  currency?: string;
  setCurrency?: (currency: string) => void;
  height: number;
  showBaseDate?: boolean;
  showNavigator?: boolean;
  showToolbar?: boolean;
};

export const DefaultOptions: ApexOptions = {
  chart: {
    id: "chart#1",
    toolbar: {
      show: true,
      tools: {
        download: false
      }
    },
    animations: {
      enabled: false,
    },
    zoom: {
      autoScaleYaxis: true,
    },
    sparkline: {
      enabled: false,
    },
  },
  xaxis: {
    type: "datetime",
    labels: {
      datetimeFormatter: {
        year: "yyyy",
        month: "yyyy.MM",
        day: "MM.dd",
        hour: "HH:mm",
      },
    },
  },
  legend: {
    fontSize: "15",
    fontWeight: "800",
    fontFamily: "Pretendard",
    // markers: {
    //   width: 13,
    //   height: 13,
    //   radius: 0,
    // },
  },
  yaxis: {
    labels: {
      formatter: (v) => (typeof v === "number" ? v.toFixed(2) : v),
    },
    //라이브러리 버그로 인해 현재 적용 안하는중. (logarithmic일때 차트 min, max option 사용시 깨짐)
    min: function (min) {
      return min;
    },
    max: function (max) {
      return max;
    },
    forceNiceScale: true,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "straight",
    show: true,
    width: [1, 1, 1],
    dashArray: 0,
  },
  tooltip: {
    enabled: true,
    x: {
      format: "yyyy-MM-dd",
    },
  },
  annotations: {},
};

const mergeOption = (extraOption) => {
  const chartOptions = { ...DefaultOptions, ...extraOption };
  return cloneDeep(chartOptions);
};

const StartEnd = ({ dates }) => {
  if (!dates) {
    return <></>;
  }
  if (dates && dates.length === 0) {
    return <></>;
  }
  return (
    <Typography variant="body1">
      {dates[0].split("T")[0]} ~ {dates[dates.length - 1].split("T")[0]}
    </Typography>
  );
};

export default function PriceLineChart(
  {
    title,
    index,
    data,
    options,
    height,
    market,
    currency,
    setCurrency,
    showBaseDate = true,
    showNavigator = false,
    showToolbar = true,
  }: PriceLineChartProps) {
  const [logToggle, setLogToggle] = useRecoilState(logToggleState);
  const setBegin = useSetRecoilState(dateRangeBeginState);
  const setEnd = useSetRecoilState(dateRangeEndState);
  const [isLog, setIsLog] = useState(false);
  const chartRef = useRef<any>();

  const chartOptions = useMemo(() => {
    let modifiedOption = mergeOption(options);
    modifiedOption.xaxis.categories = index?.map((v) => Date.parse(v));
    modifiedOption.xaxis.min = PERIOD_TIME_MAP['all']?.min;

    return modifiedOption;
  }, [options, index]);

  const toggleLogDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    const value = event.target.value ? open : false;
    setIsLog(event.target.checked);
    setLogToggle(value);
  };

  useEffect(() => {
    setIsLog(logToggle);
  }, [logToggle]);

  useEffect(() => {
    setBegin(index[0]);
    setEnd(index[index.length - 1]);
  }, [index]);

  return (
    <Box sx={{ paddingTop: 0, mt: 2 }}>
      {title && <CardHeader title={title}/>}
      {showToolbar && (
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="space-between"
        >
          <Stack direction="row">
            <FormGroup sx={{ marginLeft: "20px", marginTop: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox checked={isLog} onChange={toggleLogDrawer(true)}/>
                }
                label="Log"
              />
            </FormGroup>
            {market === "us" && setCurrency && (
              <FormGroup sx={{ marginTop: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={currency === "KRW"}
                      onChange={(e) => {
                        if (e.target.checked)
                          setCurrency("KRW");
                        else
                          setCurrency("USD");
                      }}
                    />
                  }
                  label="원화 환산"
                />
              </FormGroup>
            )}
          </Stack>
          <Stack direction="row" spacing={2} sx={{ p: 1 }}>
            {showBaseDate && <StartEnd dates={index}/>}
          </Stack>
        </Grid>
      )}
      <ReactApexChart
        ref={chartRef}
        type={"line"}
        series={data}
        options={chartOptions}
        height={height}
      />
      {showNavigator && <ChartNavigator data={data} options={chartOptions}/>}
      {logToggle && (
        <PriceLogChart series={data} date={index}/>
      )}
    </Box>
  );
}
