import { useCallback, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  staticBacktestAssetCorrelationDailyState,
  staticBacktestAssetCorrelationMonthlyState,
  staticBacktestAssetCorrelationWeeklyState,
  staticBacktestBacktestDetailState,
  staticBacktestLoadingState,
  staticBacktestMemoState,
  staticBacktestOverviewState,
  staticBacktestPortDrawDownState,
  staticBacktestPortPriceIndexState,
  staticBacktestTotalCorrelationDailyState,
  staticBacktestTotalCorrelationMonthlyState,
  staticBacktestTotalCorrelationWeeklyState,
  staticBacktestYearlyReturnState
} from "@states/staticBacktest";
import { IResBacktest } from "types/backtest";
import { ConvertToPercentTextByName } from "@utils/formatNumber";

export const ResultLoader = () => {
  const setBacktestMemo = useSetRecoilState(staticBacktestMemoState);
  const setPortPriceIndex = useSetRecoilState(staticBacktestPortPriceIndexState);
  const setOverview = useSetRecoilState(staticBacktestOverviewState);
  const setYearlyReturn = useSetRecoilState(staticBacktestYearlyReturnState);
  const setPortDrawDown = useSetRecoilState(staticBacktestPortDrawDownState);
  const setBacktestLoading = useSetRecoilState(staticBacktestLoadingState);
  const setTotalCorrelationDaily = useSetRecoilState(staticBacktestTotalCorrelationDailyState);
  const setTotalCorrelationWeekly = useSetRecoilState(staticBacktestTotalCorrelationWeeklyState);
  const setTotalCorrelationMonthly = useSetRecoilState(staticBacktestTotalCorrelationMonthlyState);
  const setAssetCorrelationDaily = useSetRecoilState(staticBacktestAssetCorrelationDailyState);
  const setAssetCorrelationWeekly = useSetRecoilState(staticBacktestAssetCorrelationWeeklyState);
  const setAssetCorrelationMonthly = useSetRecoilState(staticBacktestAssetCorrelationMonthlyState);
  const backtestDetail = useRecoilValue(staticBacktestBacktestDetailState);

  const setBacktestResultData = useCallback((backtestData: IResBacktest) => {
    setBacktestMemo(backtestData.warn);

    if (backtestData.result_timeseries) {
      setPortPriceIndex(backtestData.result_timeseries);
    }
    if (backtestData.performance) {
      const analysis_table = backtestData.performance;
      const overview = ConvertToPercentTextByName(analysis_table);
      setOverview(overview);
    }
    if (backtestData.yearly) {
      setYearlyReturn(backtestData.yearly);
    }
    if (backtestData.drawdown) {
      setPortDrawDown(backtestData.drawdown);
    }
    if (backtestData.st_corr_m) {
      setTotalCorrelationMonthly(backtestData.st_corr_m);
    }
    if (backtestData.st_corr_w) {
      setTotalCorrelationWeekly(backtestData.st_corr_w);
    }
    if (backtestData.st_corr_d) {
      setTotalCorrelationDaily(backtestData.st_corr_d);
    }
    if (backtestData.as_corr_m) {
      setAssetCorrelationMonthly(backtestData.as_corr_m);
    }
    if (backtestData.as_corr_w) {
      setAssetCorrelationWeekly(backtestData.as_corr_w);
    }
    if (backtestData.as_corr_d) {
      setAssetCorrelationDaily(backtestData.as_corr_d);
    }
    setBacktestLoading(false);

  }, [setAssetCorrelationDaily, setAssetCorrelationMonthly, setAssetCorrelationWeekly, setBacktestLoading,
    setBacktestMemo, setOverview, setPortDrawDown, setPortPriceIndex, setTotalCorrelationDaily,
    setTotalCorrelationMonthly, setTotalCorrelationWeekly, setYearlyReturn]);

  useEffect(() => {
    if (backtestDetail) {
      setBacktestResultData(backtestDetail);
    }
  }, [backtestDetail, setBacktestResultData]);

  return <></>;
};
