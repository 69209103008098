import { atom } from 'recoil';
import { AuthType } from 'types/auth';


export const authStateAtom = atom<AuthType>({
  key: 'authStateAtom',
  default: {
    isAuthenticated: false,
    isInitialized: false,
    hasProMembership: false,
    hasEliteMembership: false,
    user: null,
  },
});
