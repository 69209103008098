import { useEffect } from "react";
import { Box } from "@mui/material";
import { Stack } from "@mui/material";
import { useRecoilValue } from "recoil";
import { useSetRecoilState } from "recoil";
import { assetCompareAssetIds, assetInformationState } from "@states/asset";
import { DataGridPro } from "@mui/x-data-grid-pro";
// import { CSToolbar } from "@components/grid/CSToolbar";

export default function Information({ assetIds }) {
  const setAssetCompareAssetIds = useSetRecoilState(assetCompareAssetIds);

  useEffect(() => {
    setAssetCompareAssetIds(assetIds);
  }, [assetIds, setAssetCompareAssetIds]);

  const assetInfoList = useRecoilValue(assetInformationState);

  if (!assetInfoList || !assetInfoList.length) {
    return <></>;
  }

  const gridData = assetInfoList.map((d) => ({
    // "assetId": d.assetId,
    "ticker": d.ticker,
    "종목명": d.name,
    "자산 유형": d.assetType.replace("STOCK", "주식"),
    "상장 국가": d.country.replace("KR", "한국").replace("US", "미국"),
    "상장일": d.listingDt,
    ...((d.assetType === "ETF") ? {
      "추종 지수": d.indexTracked,
      "실부담비용률(%)": d.expenseRatio,
      "자산 분류": d.assetClass,
      "순자산가치(억원)": d.aum,
      "거래량(3달 평균)": d.averageVolume,
    } : {}),
    ...((d.assetType === "STOCK") ? {
      "시장": d.market,
      "섹터": d.sector,
      "산업": d.industry,
      "거래량(3달 평균)": d.averageVolume,
    } : {}),
  }));

  let columns = gridData.map((data) => ({
    field: data["ticker"],
    headerName: data["ticker"],
    width: 200,
  }));
  const overViewColumns = [
    { field: "index", headerName: "Ticker", width: 150, type: "number" },
    ...columns,
  ];

  const formatData = Object.keys(gridData[0]).map((value) => {
    let temp = {};
    gridData.forEach((data) => {
      let c = data["ticker"];
      temp[c] = data[value];
    });

    return temp;
  });
  const indexData = Object.keys(gridData[0]).map((data) => ({ index: data }));
  const rows = formatData
    .map((data, idx) => ({ ...data, ...indexData[idx] }))
    .filter((value) => value.index !== "ticker");

  return (
    <Box sx={{ height: 400, m: 0, my: 2 }}>
      <Stack style={{ height: "500px", width: "100%" }} spacing={1}>
        <DataGridPro
          initialState={{ pinnedColumns: { left: ['index'] } }}
          // components={{ Toolbar: CSToolbar }}
          columns={overViewColumns}
          rows={rows}
          density="compact"
          getRowId={(row) => row.index}
        />
      </Stack>
    </Box>
  );
}
