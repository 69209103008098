import { atom, selector } from "recoil";
import { DateRangeType } from "types/dateRange";
import axios from "@utils/axios";
import { responseDateValidate } from "@utils/formatTime";


export type AnalysisTableType = {
  values: Array<any>;
  index: string[];
};

export const logToggleState = atom({
  key: "logToggleState",
  default: false,
});

export const dateRangeBeginState = atom<string | null>({
  key: "dateRangeBeginState",
  default: null,
})

export const dateRangeEndState = atom<string | null>({
  key: "dateRangeEndState",
  default: null,
})

export const assetCompareDateRangeState = atom<DateRangeType>({
  key: "assetCompareDateRangeState",
  default: {
    begin: null,
    end: null,
  },
});

export const assetCompareDetailState = atom({
  key: "assetComparedetail",
  default: {
    loaded: false,
    analysis_table: undefined,
    price_reindex: undefined,
    total_correlation_table: undefined,
    monthly_correlation_table: undefined,
    total_draw_down: undefined,
    total_ret_arith_yearly: undefined,
    yoy: undefined,
    mom: undefined,
    ytd: undefined,
  },
});

export const assetCompareAnalysisState = selector({
  key: "assetCompareAnalysisState",
  get: ({ get }) => {
    const { analysis_table } = get(assetCompareDetailState);
    return analysis_table;
  },
});

export const assetComparePriceState = selector({
  key: "assetComparePriceState",
  get: ({ get }) => {
    const { price_reindex } = get(assetCompareDetailState);
    return price_reindex;
  },
});

export const assetCompareCorrelationState = selector({
  key: "assetCompareCorrelationState",
  get: ({ get }) => {
    const { total_correlation_table } = get(assetCompareDetailState);
    return total_correlation_table;
  },
});

export const assetCompareMonthlyCorrelationState = selector({
  key: "assetCompareMonthlyCorrelationState",
  get: ({ get }) => {
    const { monthly_correlation_table } = get(assetCompareDetailState);
    return monthly_correlation_table;
  },
});

export const assetCompareDrawDownState = selector({
  key: "assetCompareDrawDownState",
  get: ({ get }) => {
    const { total_draw_down } = get(assetCompareDetailState);
    return total_draw_down;
  },
});

export const assetCompareYoYState = selector({
  key: "assetCompareYoYState",
  get: ({ get }) => {
    const { yoy } = get(assetCompareDetailState);
    return yoy;
  },
});

export const assetCompareYTDState = selector({
  key: "assetCompareYTDState",
  get: ({ get }) => {
    const { ytd } = get(assetCompareDetailState);
    return ytd;
  },
});

export const assetCompareMoMState = selector({
  key: "assetCompareMoMState",
  get: ({ get }) => {
    const { mom } = get(assetCompareDetailState);
    return mom;
  },
});

export const assetCompareYearlyReturnState = selector({
  key: "assetCompareYearlyReturnState",
  get: ({ get }) => {
    const { total_ret_arith_yearly } = get(assetCompareDetailState);
    return total_ret_arith_yearly;
  },
});

export const assetCompareAssetIds = atom({
  key: "assetCompareAssetIdsState",
  default: [],
});

export const assetInformationState = selector({
  key: "assetInformationState",
  get: async ({ get }) => {
    const assetIds = get(assetCompareAssetIds);
    if (!Array.isArray(assetIds))
      return;
    if (assetIds.length === 0)
      return;
    const url = "/assets/info";
    const response = await axios.get(url, { params: { "assetIds": assetIds.toString() } });
    return response.data;
  }
});

export const selectAssetDiffData = async (
  assetIds,
  period = "D",
  begin,
  end,
) => {
  const url = "/bts/assets/compare";

  const response = await axios.post(url, {
    assetIds,
    period,
    begin,
    end,
  });
  if (response.data) {
    const data = response.data;
    const validationMessage = responseDateValidate(
      [begin, end],
      data.price_reindex.index
    );
    response.data.warning = validationMessage;
  }

  return response.data;
};

export const selectAssetComparisonData = async (
  assetIds,
  period = "D",
  begin: null | string = "20000101",
  end = null
) => {
  const url = "data/asset/compare";
  const response = await axios.post(url, {
    assetIds,
    period,
    begin,
    end,
  });

  if (response.data) {
    const data = response.data;
    const validationMessage = responseDateValidate(
      [begin, end],
      data.price_reindex.index
    );
    data.warning = validationMessage;
  }

  return response.data;
};

// export const assetCatalogState = selector({
//   key: "assetCatalogtState",
//   get: async ({ get }) => {
//     const response = await axios.get("assets/catalog");

//     return response.data.map((asset) => ({
//       "assetId": asset.assetId,
//       "name": asset.name,
//       "ticker": asset.ticker,
//       "label": `[${asset.ticker}] ${asset.name}`,
//     }));
//   },
// });

export const assetETFKRCatalogState = selector({
  key: "assetETFKRCatalogtState",
  get: async ({ get }) => {
    const response = await axios.get("assets/catalog?assetType=ETF&country=KR");

    return response.data.map((asset) => ({
      "assetId": asset.assetId,
      "name": asset.name,
      "ticker": asset.ticker,
      "label": `[${asset.ticker}] ${asset.name}`,
    }));
  },
});

export const assetETFUSCatalogState = selector({
  key: "assetETFUSCatalogtState",
  get: async ({ get }) => {
    const response = await axios.get("assets/catalog?assetType=ETF&country=US");

    return response.data.map((asset) => ({
      "assetId": asset.assetId,
      "name": asset.name,
      "ticker": asset.ticker,
      "label": `[${asset.ticker}] ${asset.name}`,
    }));
  },
});

export const assetStockKRCatalogState = selector({
  key: "assetStockKRCatalogtState",
  get: async ({ get }) => {
    const response = await axios.get("assets/catalog?assetType=STOCK&country=KR");

    return response.data.map((asset) => ({
      "assetId": asset.assetId,
      "name": asset.name,
      "ticker": asset.ticker,
      "label": `[${asset.ticker}] ${asset.name}`,
    }));
  },
});

export const assetStockUSCatalogState = selector({
  key: "assetStockUSCatalogtState",
  get: async ({ get }) => {
    const response = await axios.get("assets/catalog?assetType=STOCK&country=US");

    return response.data.map((asset) => ({
      "assetId": asset.assetId,
      "name": asset.name,
      "ticker": asset.ticker,
      "label": `[${asset.ticker}] ${asset.name}`,
    }));
  },
});