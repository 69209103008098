import React from "react";
import { stockSelectedTickerState } from "@states/stock";
import { useRecoilValue } from "recoil";
import DetailChartPanel from "./Panel/DetailChartPanel";
import AnalysisPanel from "./Panel/AnalysisPanel";
import { TabContents } from "@components/tab/TabPanel";

const Contents = [
  { 
    label: "가상 지수",
    component: <DetailChartPanel/>,
  },
  {    
    label: "분석",
    component: <AnalysisPanel/>,
  },
];

function DataTabs() {
  const selectedTicker = useRecoilValue(stockSelectedTickerState);
  return (
    <>
      {selectedTicker && (
       <TabContents tabs={Contents} />
      )}
    </>
  );
}

export default DataTabs;
