import { Form, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
// material
import { Alert, Divider, Stack, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import MIconButton from "@components/button/MIconButton";
import useIsMountedRef from "@hooks/useIsMountedRef";
import axios from "@utils/axios";

const forgotPassword = async (email: string) => {

  await axios({
    method: "post",
    url: "auth/forgot-password",
    data: { "email": email },
  });

  return true;
};

export default function ForgotPasswordForm({ toggleForm }: { toggleForm: () => void }) {
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("올바른 이메일을 입력해주세요.")
      .required("이메일을 입력해주세요."),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      afterSubmit: ""
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        await forgotPassword(values.email);

        enqueueSnackbar("임시 비밀번호가 발송되었습니다.", {
          variant: "success",
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill}/>
            </MIconButton>
          ),
        });

        if (isMountedRef.current) {
          setSubmitting(false);
        }

        toggleForm();

      } catch (error: any) {
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          if (error.detail === "USER_EMAIL_NOT_FOUND")
            setErrors({ afterSubmit: "유효하지 않은 이메일입니다. 가입하신 이메일을 확인해주세요." });
          else if (error.detail === "USER_EMAIL_INACTIVE")
            setErrors({ afterSubmit: "비활성화된 유저 이메일입니다." });

          // setErrors({ afterSubmit: error.detail });
        }
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="email"
            type="email"
            label="이메일"
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          {errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit}</Alert>
          )}
        </Stack>
        <Stack sx={{ my: 2 }}/>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          비밀번호 재설정 이메일 받기
        </LoadingButton>

        <Stack
          justifyContent="space-between"
          sx={{ my: 3 }}
          spacing={3}
        >
          <Divider />
          <Typography
            align="left"
            onClick={() => toggleForm && toggleForm()}
            variant="subtitle2"
            sx={{ color: "text.disabled", textDecoration: "underline", cursor: "pointer" }}
            noWrap={true}
          >
            다시 로그인하기
          </Typography>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
