import { atom, selector } from "recoil";
import axios from "@utils/axios";
import { AssetType } from "types/common";
import { DateRangeType } from "types/dateRange";


export const valueState = atom({
  key: "stockValueState",
  default: 0,
});

export const stockChartDateRangeState = atom<DateRangeType>({
  key: "stockChartDateState",
  default: {
    begin: null,
    end: null,
  },
});

export const stockChartCurrencyState = atom({
  key: "stockChartCurrencyState",
  default: "KRW",
})

export const stockSelectedTickerState = atom<AssetType | null>({
  key: "stockSelectedTickerState",
  default: null,
});

export const stockQueryFilterState = atom<any | null>({
  key: "stockQueryFilterState",
  default: "KR"
});

export const stockListingState = selector({
  key: "stockListingState",
  get: async ({ get }) => {
    const countryFilter = get(stockQueryFilterState);
    const params = {
      "assetType": "STOCK",
    };
    if (countryFilter) {
      if (countryFilter !== 'ALL') {
        params['country'] = countryFilter
      }
    }
    const response = await axios.get(`assets`, {
      params: params
    });

    // 한국 주식 목록에서 우선주 제외
    return response.data.filter(
      x => !(x.country === 'KR' && x.ticker.slice(-1) !== '0')
    );
  },
});

export const analysisDataState = atom({
  key: "stockAnalysisDataState",
  default: { load: false }
});

export const stockAnalysisCurrencyState = atom({
  key: "stockAnalysisCurrencyState",
  default: "KRW",
})

export const stockChartDataState = selector({
  key: "stockChartDataState",
  get: async ({ get }) => {
    const selected = get(stockSelectedTickerState);
    if (selected === null)
      return;
    const { assetId } = selected;
    const dataParam = get(stockChartDateRangeState);
    const currency = get(stockChartCurrencyState);
    const params = {
      "begin": dataParam.begin,
      "end": dataParam.end,
      "currency": currency,
    }

    const response = await axios.get(
      `bts/assets/${assetId}/proxy`,
      { params: params }
    );
    return response.data;
  }
});
