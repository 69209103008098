// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

// const ROOTS = "";
const ROOTS_AUTH = "/auth";
export const ETF_ROOT = "/etf";
// const FUND_ROOT = "/fund"
export const STOCK_ROOT = '/stock';
const BACKTEST_ROOT = "/backtest";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
};

export const PATH_ETF = {
  root: ETF_ROOT,
  // kr: path(ETF_ROOT, "/kr"),
  // us: path(ETF_ROOT, "/us"),
};

// export const PATH_FUND = {
//   root: FUND_ROOT,
//   kr: path(FUND_ROOT, "/kr"),
// }

export const PATH_STOCK = {
  root: STOCK_ROOT,
  // kr: path(STOCK_ROOT, "/kr"),
  // us: path(STOCK_ROOT, "/us"),
}

export const PATH_BACKTEST = {
  root: BACKTEST_ROOT,
  staticBacktest: path(BACKTEST_ROOT, "/static"),
};
