// import { useState } from "react";
import { useRecoilValue } from "recoil";
import { Box } from "@mui/material";
import PriceLineChart from "@components/chart/PriceLineChart";
import { assetComparePriceState } from "@states/asset";
// import LoadingButton from "@components/button/LoadingButton";
// import { downloadExcelPost } from "@utils/download";

export default function LineChart() {
  const data = useRecoilValue(assetComparePriceState);
  if (!data) {
    return <></>;
  }
  const { values, index } = data;
  return (
    <>
      <Box sx={{ height: 500, m: 0, my: 5 }}>
        <PriceLineChart index={index} data={values} height={400}/>
      </Box>
    </>
  );
}
