import * as React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LogarithmicScale,
  TimeScale,
  Colors,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { logToggleState } from "@states/asset";
import { useRecoilState } from "recoil";
import { Drawer } from "@mui/material";
import Log2Axis from "./Log2Axis"
import 'chartjs-adapter-date-fns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LogarithmicScale,
  TimeScale,
  Colors,
  Log2Axis
);
export default function PriceLogChart({ series, date }) {
  const [logToggle, setLogToggle] = useRecoilState(logToggleState);
  const options = {
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    response: false,
    animation: {
      duration: 0,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    plugins: {
      title: {
        display: true,
        font: { size: 16 },
        text: "로그 스케일 차트" ,
      },
      colors: {
        forceOverride: true,
      },
      tooltip: {
        enabled: true,
        titleFont: {
          size: 15,
        },
        bodyFont: {
          size: 13,
        },
        footerFont: {
          size: 13,
        },
        callbacks: {
          label: function (tooltipItem, data) {
            return (
              tooltipItem.dataset.label +
              ": " +
              Number(tooltipItem.raw)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")
            );
          },
        },
      },
      legend: {
        labels: {
          font: {
            weight: "bolder",
            size: 13
          }
        }
      }
    },
    scales: {
      x: {
        type: "time",
        time: {
          tooltipFormat: "yyyy-MM-dd",
          unit: "year",
          displayFormats: {
            year: "yyyy",
          },
        },
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 20,
        },
      },
      y: {
        display: true,
        type: "log2",
        ticks: {
          autoSkip: true,
        },
      },
    },
  };

  const palette = ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0", "#4CAF50", "#F9CE1D", "#03A9F4", "#3F51B5", "#546E7A", "#D4526E", "#13D8AA", "#F9A3A4", "#662E9B", "#8D5B4C", "#1B998B"] 
  
  const datasets = series?.map((v, idx) => {
    return {
      type: "line",
      label: v.name,
      data: v.data,
      borderWidth: 1,
      radius: 0,
      backgroundColor: palette[idx],
      borderColor: palette[idx],
      xValueType: "dateTime",
    };
  });

  return (
    <Drawer
      style={{ zIndex: 9999 }}
      PaperProps={{
        sx: {
          borderRadius: "0 0 5% 5%",
          width: "100%",
          height: "500px",
          p: 2
        },
      }}
      anchor={"top"}
      open={logToggle}
      onClose={() =>setLogToggle(false)}
    >
      <Line
        options={options}
        data={{ labels: date, datasets: datasets }}
        width={"100%"}
        height={"50%"}
      />
    </Drawer>
  );
}
