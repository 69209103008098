import { AssetType } from 'types/common';
import { DateRangeType } from "types/dateRange";
import axios from "@utils/axios";
import { downloadExcel } from "@utils/download";

export const getAnalysis = async (
  assetId: string,
  begin: string | null = "20000101",
  end: string | null = null,
  currency: string | null = null,
) => {
  const url = `bts/assets/${assetId}/analysis`;
  const response = await axios.get(url, { params: { begin, end, currency } });
  return response.data;
};


export async function downloadProxyChartExcel(asset: AssetType, freq: string = "D", currency?: string, dateRange?: DateRangeType) {
  const filenameSuffixes: string[] = [];
  if (currency) {
    filenameSuffixes.push(currency);
  }

  if (freq === "W") {
    filenameSuffixes.push("주간");
  } else if (freq === "M") {
    filenameSuffixes.push("월간");
  }

  if (dateRange && dateRange.begin && dateRange.end) {
    filenameSuffixes.push(`${dateRange.begin}~${dateRange.end}`);
  }

  let filename = `[${asset.ticker}] ${asset.name}`;
  if (filenameSuffixes.length > 0) {
    filename += `(${filenameSuffixes.join(", ")})`;
  }

  const url = `bts/assets/${asset.assetId}/proxy/excel`;

  const params = {
    freq: freq,
    currency: currency,
    begin: dateRange?.begin,
    end: dateRange?.end,
  }

  await downloadExcel(url, filename, params);
}
