import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import CartMenu from "@components/CartMenu";
import AssetDiffView from "@pages/asset/comparison/PopupView";
import { useOpenSnackbar } from "@hooks/useSnackbar";

type SideBarProps = {
  checkedAssets: object[],
  assetType: string,
  market?: string,
  onChange: Function,
  assetFilter?: Function,
  enableDownload?: boolean
}

export function SideBar({ checkedAssets, assetType, market, onChange, assetFilter, enableDownload = false }: SideBarProps) {
  const [isCompareOpen, setIsCompareOpen] = useState(false);
  const [compareAssets, setCompareAssets] = useState([]);
  const [selectedData, setSelectedData] = useState<Array<any> | any>(null);
  const [marketSelect, setMarketSelect] = useState("");

  const openSnackbar = useOpenSnackbar();

  const saveKey = useMemo(() => {
    return `${assetType}`;
  }, [assetType]);

  useEffect(() => {
    setMarketSelect(saveKey);
  }, [saveKey, setMarketSelect]);

  useEffect(() => {
    // 첫 로딩
    if (selectedData === null) {
      loadData();
    }
    if (saveKey === marketSelect) {
      const newData = _.uniqBy([...checkedAssets, ...selectedData], "assetId");
      saveData(newData);
    }
  }, [checkedAssets]);

  useEffect(() => {
    loadData();
  }, [marketSelect]);

  const handleShow = (assetIds) => {
    if (assetIds.length > 1) {
      if (assetFilter) {
        assetIds = assetFilter(assetIds);
      }
      setCompareAssets(assetIds);
      setIsCompareOpen(true);
    } else {
      openSnackbar("비교 자산을 2개 이상 선택해주세요.", "warning");
    }
  };

  const handleChange = (e) => {
    if (onChange !== null) {
      onChange(e);
      saveData(e);
    }
  };

  const saveData = (objArray) => {
    if (saveKey === marketSelect) {
      setSelectedData(objArray);
      localStorage.setItem(marketSelect, JSON.stringify(objArray));
    }
  };

  const loadData = () => {
    const value = localStorage.getItem(marketSelect);
    let loadList = [];

    if (typeof value === "string") {
      loadList = JSON.parse(value) || []; // ok
    }
    setSelectedData(loadList);
  };

  return (
    <>
      <CartMenu
        data={selectedData}
        onSubmit={handleShow}
        onChange={(e) => handleChange(e)}
        marketSelect={marketSelect}
      />
      {isCompareOpen && (
        <AssetDiffView
          show={isCompareOpen}
          assetType={marketSelect}
          assetIds={compareAssets}
          enableDownload={enableDownload}
          onClose={() => {
            setIsCompareOpen(false);
          }}
        />
      )}
    </>
  );
}
