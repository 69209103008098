import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import TimeSeriesChart from "@components/chart/TimeSeriesChart";
import TabPanel, { simpeTabProps } from "@components/tab/TabPanel";
import HeatmapChart from "@components/chart/HeatmapChart";
// import { CSToolbar } from "@components/grid/CSToolbar";
import { addHyphen } from "@utils/formatTime";
import {
  staticBacktestAssetCorrelationDailyState,
  staticBacktestAssetCorrelationMonthlyState,
  staticBacktestAssetCorrelationWeeklyState,
  staticBacktestBacktestDetailState,
  staticBacktestMemoState,
  staticBacktestOverviewState,
  staticBacktestPortDrawDownState,
  staticBacktestPortPriceIndexState,
  staticBacktestTotalCorrelationDailyState,
  staticBacktestTotalCorrelationMonthlyState,
  staticBacktestTotalCorrelationWeeklyState,
  staticBacktestYearlyReturnState
} from "@states/staticBacktest";
import { StyledCard } from "@components/ui/styles";
import { IBTSObject } from 'types/backtest';


const OverViewPanel = () => {
  const overview = useRecoilValue(staticBacktestOverviewState);

  let portColumns: GridColDef[] = [];
  if (overview.length !== 0) {
    portColumns = Object.keys(overview[0]).filter((data) => data !== "index").map((portName) => ({
      field: portName,
      headerName: portName,
      width: 170,
      type: "number",
    }));
  }

  const overviewColumns = [
    { field: "index", headerName: "지표", width: 200, type: "number" },
    ...portColumns,
  ];

  return (
    <Box style={{ height: 800 }}>
      <DataGridPro
        columns={overviewColumns}
        rows={overview}
        density="compact"
        disableSelectionOnClick
        // components={{ Toolbar: CSToolbar }}
        getRowId={(row) => row.index}
      />
    </Box>
  );
};

const PriceIndexChart = ({ value }) => {
  const portPriceIndex = useRecoilValue(staticBacktestPortPriceIndexState);

  const priceIndexChartData = {
    values: portPriceIndex?.values,
    index: portPriceIndex?.dates.map((data) =>
      data.replace(/(\d{4})(\d{2})(\d{2})/g, "$1-$2-$3")
    ),
  };
  return (
    <Box style={{ height: 800 }}>
      <TimeSeriesChart data={priceIndexChartData} showToolbar={true}/>
    </Box>
  );
};

export const MemoList = () => {
  const backtestMemo = useRecoilValue(staticBacktestMemoState);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(backtestMemo && backtestMemo.length > 0);
  }, [backtestMemo])

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = (e) => {
    setOpen(false);
  };

  return (
    <>
      {backtestMemo && backtestMemo.length > 0 ? (
        <Card sx={{ p: 5, m: 1, pt: 3 }}>
          <Typography
            sx={{
              fontSize: 25,
              fontWeight: "bold",
              color: "#ff9966",
              mb: 2,
              textAlign: "center",
            }}
          >
            조정내역
          </Typography>
          <ul>
            {backtestMemo.map((memo, memoIndex) => (
              <li
                key={memoIndex}
                style={{ fontWeight: "bold", color: "#ff9966" }}
              >
                {memo}
              </li>
            ))}
          </ul>
        </Card>
      ) : null}
      {
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Card sx={{ p: 5, m: 1, pt: 3 }}>
              <Typography
                variant="h3"
                sx={{
                  color: "#ff9966",
                  mb: 2,
                  textAlign: "center",
                }}
              >
                백테스트 조정 내역
              </Typography>
              <ul>
                {backtestMemo.map((memo, memoIndex) => (
                  <li
                    key={memoIndex}
                    style={{ fontWeight: "bold", color: "#ff9966" }}
                  >
                    {memo}
                  </li>
                ))}
              </ul>
            </Card>
          </DialogTitle>
          <DialogActions>
            <Button onClick={(e) => handleClose(e)}>확인</Button>
          </DialogActions>
        </Dialog>
      }
    </>
  );
};

const YearlyReturn = ({ value }) => {
  const yearlyReturn = useRecoilValue(staticBacktestYearlyReturnState);
  let yearlyReturnGridColumns: GridColDef[] = [];
  if (yearlyReturn.length !== 0) {
    const yearlyReturnGridColumnsKeys = Object.keys(yearlyReturn[0]).filter(
      (data) => data !== "index"
    );
    yearlyReturnGridColumns = yearlyReturnGridColumnsKeys.map((portName) => ({
      field: portName,
      headerName: portName,
      width: 170,
      type: "number",
      renderCell: (params) => {
        const style = {
          color: params.value > 0 ? "red" : "blue",
        };
        return (
          <strong style={style}>{`${(params.value * 100).toFixed(
            2
          )} %`}</strong>
        );
      },
    }));
  }

  const totalYearlyReturnGridColums = [
    {
      field: "index",
      headerName: " ",
      width: 80,
      valueFormatter: ({ value }) => value.split("-")[0],
    },
    ...yearlyReturnGridColumns,
  ];

  return (
    <Box style={{ height: 800 }}>
      <DataGridPro
        initialState={{ pinnedColumns: { left: ["index"] } }}
        columns={totalYearlyReturnGridColums}
        rows={yearlyReturn}
        density="compact"
        disableSelectionOnClick
        // components={{ Toolbar: CSToolbar }}
        getRowId={(row) => row.index}
      />
    </Box>
  );
};

const DrawDown = ({ value }) => {
  const portDrawDown = useRecoilValue(staticBacktestPortDrawDownState);
  let portDrawDownIndexes: GridColDef[] = [];
  const portDrawDownValues: object[] = [];

  if (portDrawDown.length !== 0) {
    const portDrawDownKeys = Object.keys(portDrawDown[0]).filter(
      (data) => data !== "index"
    );
    portDrawDownIndexes = portDrawDown.map((data) => addHyphen(data.index));
    portDrawDownKeys.forEach((key) => {
      portDrawDownValues.push({
        name: key,
        data: portDrawDown.map((data) => data[key].toFixed(4)),
      });
    });
  }

  const portDrawDownChartData = {
    index: portDrawDownIndexes,
    values: portDrawDownValues,
  };
  return (
    <Stack spacing={3}>
      <Box style={{ height: 800 }}>
        <TimeSeriesChart
          data={portDrawDownChartData}
          extraChartOptions={{
            yaxis: {
              max: 0,
              tickAmount: 5,
              labels: {
                formatter: (v) =>
                  typeof v === "number" ? (v * 100).toFixed() + "%" : v,
              },
            },
          }}
        />
      </Box>
    </Stack>
  );
};

const to_freq_label = (v: string) => {
  if (v === "daily")
    return "일별";
  else if (v === "weekly")
    return "주별";
  else if (v === "monthly")
    return "월별";
  return v;
};

const Correlation = ({ index }) => {
  const [value, setValue] = useState("monthly");

  const [assetCorrelation, setAssetCorrelation] = useState<IBTSObject[][]>();
  const [totalCorrelation, setTotalCorrelation] = useState<IBTSObject[]>();

  const monthlyTotal = useRecoilValue(staticBacktestTotalCorrelationMonthlyState);
  const monthlyAsset = useRecoilValue(staticBacktestAssetCorrelationMonthlyState);

  const weeklyTotal = useRecoilValue(staticBacktestTotalCorrelationWeeklyState);
  const weeklyAsset = useRecoilValue(staticBacktestAssetCorrelationWeeklyState);

  const dailyTotal = useRecoilValue(staticBacktestTotalCorrelationDailyState);
  const dailyAsset = useRecoilValue(staticBacktestAssetCorrelationDailyState);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (value === "daily") {
      setAssetCorrelation(dailyAsset);
      setTotalCorrelation(dailyTotal);
    }
    if (value === "weekly") {
      setAssetCorrelation(weeklyAsset);
      setTotalCorrelation(weeklyTotal);
    }
    if (value === "monthly") {
      setAssetCorrelation(monthlyAsset);
      setTotalCorrelation(monthlyTotal);
    }
  }, [value, dailyAsset, dailyTotal, weeklyAsset, weeklyTotal, monthlyAsset, monthlyTotal]);

  let totalCorrelationData: object[] = [];
  let assetCorrelationData: object[][] = [];

  if (totalCorrelation) {
    totalCorrelationData = totalCorrelation.map((item) => {
      const { index, ...data } = item;
      return {
        name: index,
        data: Object.keys(data)
          .map((k) => ({ x: k, y: data[k] }))
          .reverse(),
      };
    });
  }

  if (assetCorrelation) {
    assetCorrelation.map((item_list: IBTSObject[]) => {
      assetCorrelationData.push(
        item_list.map((item: IBTSObject) => {
            const { index, ...data } = item;
            return {
              name: index,
              data: Object.keys(data)
                .map((k) => ({ x: k, y: data[k] }))
                .reverse(),
            };
          }
        ));
    });
  }

  return (
    <Box style={{ minHeight: 800 }}>
      <Stack spacing={3}>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
          row
        >
          <FormControlLabel value="daily" control={<Radio/>} label="일간"/>
          <FormControlLabel value="weekly" control={<Radio/>} label="주간"/>
          <FormControlLabel value="monthly" control={<Radio/>} label="월간"/>
        </RadioGroup>
        <HeatmapChart
          title={`전략간 상관계수 ${to_freq_label(value)}`}
          data={totalCorrelationData}
        />
        {assetCorrelationData.map((asset, index) => (
          <HeatmapChart key={index} title={`종목간 상관계수 ${to_freq_label(value)}`} data={asset}/>
        ))}
      </Stack>
    </Box>
  );
};

export default function DataTabs() {
  const [value, setValue] = useState(0);
  const portPriceIndex = useRecoilValue(staticBacktestPortPriceIndexState);
  const backtestDetail = useRecoilValue(staticBacktestBacktestDetailState);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <MemoList/>
      <StyledCard>
        <Stack direction="column" spacing={0.5} alignItems="left" justifyContent="left">
          <Typography sx={{ fontWeight: 700, fontSize: "19px" }}>
            {`[백테스트 기간] : ${addHyphen(portPriceIndex?.dates[0])} ~ ${addHyphen(portPriceIndex?.dates[portPriceIndex.dates.length - 1])}`}
          </Typography>
          <Typography sx={{ fontWeight: 700, fontSize: "19px" }}>
            {`[거래 비용] : ${(backtestDetail?.request_payload?.cost || 0) * 100}%`}
          </Typography>
        </Stack>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="요약" {...simpeTabProps(0)} />
            <Tab label="시계열" {...simpeTabProps(1)} />
            <Tab label="연도별 성과" {...simpeTabProps(2)} />
            <Tab label="낙폭" {...simpeTabProps(3)} />
            <Tab label="상관관계" {...simpeTabProps(4)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <OverViewPanel/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PriceIndexChart value={value}/>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <YearlyReturn value={value}/>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <DrawDown value={value}/>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Correlation index={value}/>
        </TabPanel>
      </StyledCard>
    </>
  );
}
