import { atom, selector } from "recoil";
import _ from "lodash";
import { STATIC_STRATEGY_DEFAULT } from "@pages/backtest/define";
import { IPaginationRequest, IPagination, ITimeseriesDataFrame } from 'types/api';
import {
  IBTSObject,
  IResBacktest,
  IResBacktestHistory,
  IBacktestStaticStrategy,
  IBacktestStaticConfig,
} from 'types/backtest';
import axios from "@utils/axios";

export const staticBacktestMemoState = atom<string[]>({
  key: "staticBacktestMemoState",
  default: [],
});

export const staticBacktestPortPriceIndexState = atom<ITimeseriesDataFrame | null>({
  key: "staticBacktestPortPriceIndexState",
  default: null,
});

export const staticBacktestOverviewState = atom<IBTSObject[]>({
  key: "staticBacktestOverviewState",
  default: [],
});

export const staticBacktestYearlyReturnState = atom<IBTSObject[]>({
  key: "staticBacktestYearlyReturnState",
  default: [],
});

export const staticBacktestPortDrawDownState = atom<IBTSObject[]>({
  key: "staticBacktestPortDrawDownState",
  default: [],
});

export const staticBacktestTotalCorrelationDailyState = atom<IBTSObject[]>({
  key: "staticBacktestTotalCorrelationDailyState",
  default: [],
});

export const staticBacktestTotalCorrelationWeeklyState = atom<IBTSObject[]>({
  key: "staticBacktestTotalCorrelationWeeklyState",
  default: [],
});

export const staticBacktestTotalCorrelationMonthlyState = atom<IBTSObject[]>({
  key: "staticBacktestTotalCorrelationMonthlyState",
  default: [],
});

export const staticBacktestAssetCorrelationDailyState = atom<IBTSObject[][]>({
  key: "staticBacktestAssetCorrelationDailyState",
  default: [],
});

export const staticBacktestAssetCorrelationWeeklyState = atom<IBTSObject[][]>({
  key: "staticBacktestAssetCorrelationWeeklyState",
  default: [],
});

export const staticBacktestAssetCorrelationMonthlyState = atom<IBTSObject[][]>({
  key: "staticBacktestAssetCorrelationMonthlyState",
  default: [],
});

//strategies
export const staticBacktestStrategiesState = atom<IBacktestStaticStrategy[]>({
  key: "staticBacktestStrategiesState",
  default: [_.cloneDeep(STATIC_STRATEGY_DEFAULT)],
});

export const staticBacktestBacktestDetailState = atom<IResBacktest | null>({
  key: "staticBacktestBacktestDetailState",
  default: null,
});

export const staticBacktestLoadingState = atom<boolean>({
  key: "staticBacktestLoadingState",
  default: false,
});

export const staticBacktestHistoriesQueryPage = atom<number>({
  key: "staticBacktestHistoriesQueryPage",
  default: 0,
});

export const staticBacktestHistoriesQueryPageSize = atom<number>({
  key: "staticBacktestHistoriesQueryPageSize",
  default: 10,
});

export const staticBacktestHistoriesQuery = selector<IPagination<IResBacktestHistory>>({
  key: "staticBacktestHistories",
  get: async ({ get }) => {
    let url = "backtest/histories?";
    const page = get(staticBacktestHistoriesQueryPage) + 1;
    const size = get(staticBacktestHistoriesQueryPageSize);

    if (!!page) url += `page=${page}&`;
    if (!!size) url += `size=${size}&`;
  
    const response = await axios.get(url);
    return response.data;
  },
});

export const staticBacktestInitialConfig: IBacktestStaticConfig = {
  cost: 0,
  name: "",
  bt_end: "",
  bt_start: "",
  initial_amount: 100,
  benchmark: [""],
  data_freq: "D",
  port_list: [],
  strategies: [],
  currency: "KRW",
};

export const staticBacktestConfigState = atom<IBacktestStaticConfig>({
  key: "staticBacktestConfigState",
  default: staticBacktestInitialConfig,
});
