import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { every } from "lodash";
import { useSetRecoilState } from "recoil";

import { assetCompareDetailState } from "@states/asset";

import AnalyticsChart from "@components/chart/AnalyticsChart";
// import { LoadingOveray } from "@components/LoadingScreen";
import { SectionList } from "@components/SectionList";

import ChartMoM from "../comparison/ChartMoM";
import ChartYoY from "../comparison/ChartYoY";
import DrawDown from "../comparison/DrawDown";
import Summary from "../comparison/Summary";
import YearReturn from "../comparison/YearReturn";
import YTDTable from "../comparison/YTDTable";


export const createTabData = (props) => {
  return [
    {
      id: 1,
      children: <AnalyticsChart {...props} />,
      label: "시계열 분석",
    },
    {
      id: 2,
      children: <DrawDown {...props} />,
      label: "낙폭",
    },
    {
      id: 3,
      children: <Summary {...props} />,
      label: "요약",
    },
    {
      id: 4,
      children: <YearReturn {...props} />,
      label: "연도별 성과",
    },
    {
      id: 5,
      children: <ChartMoM {...props} />,
      label: "MoM (전월 대비 증감율) ",
    },
    {
      id: 6,
      children: <ChartYoY {...props} />,
      label: "YoY (전년 대비 증감율) ",
    },
    {
      id: 7,
      children: <YTDTable {...props} />,
      label: "YTD (연초대비 수익률)",
    },
  ];
};

function Content({ market, ticker, assetType }) {
  return <SectionList sections={createTabData({ market, ticker, assetType })}/>;
}


export function AnalysisDetail({ ticker, analysisData }) {
  const setAssetCompareDetail = useSetRecoilState(assetCompareDetailState);
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    setAssetCompareDetail(analysisData);
  }, [analysisData, setAssetCompareDetail]);

  return (
    <Box style={{ marginTop: "16px", minHeight: "500px", maxWidth: "100%" }}>
      {every(Object.values(analysisData)) && (
        <Content market="" assetType={""} ticker={ticker}/>
      )}
      {/*{loading && <LoadingOveray show={!loading} />}*/}
    </Box>
  );
}

export default AnalysisDetail;
