// import { useState } from "react";
import { Box } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { useRecoilValue } from "recoil";

import { AnalysisTableType, assetCompareYearlyReturnState, } from "@states/asset";
// import { CSToolbar } from "@components/grid/CSToolbar";
// import { CsSwitch } from "@components/CsSwitch";
// import { ReturnBarChart } from "@components/chart/ReturnBarChart";

function createColumns(index): GridColumns {
  const yearlyReturnGridColums = index.map((portName) => ({
    field: portName,
    headerName: portName,
    width: 150,
    type: "number",
    renderCell: (params) => {
      const style = {
        color: params.value > 0 ? "red" : "blue",
      };
      return (
        <strong style={style}>{`${(params.value * 100).toFixed(2)} %`}</strong>
      );
    },
  }));

  const totalYearlyReturnGridColums = [
    {
      field: "index",
      headerName: "Year",
      width: 80,
      valueFormatter: ({ value }) => value.split("-")[0],
    },
    ...yearlyReturnGridColums,
  ];
  return totalYearlyReturnGridColums;
}

export default function YearReturn() {
  const yearlyReturn = useRecoilValue(assetCompareYearlyReturnState);
  // const [checked, setChecked] = useState(false);

  if (!yearlyReturn) {
    return <></>;
  }

  const { values, index }: AnalysisTableType = yearlyReturn;
  const gridColumns = createColumns(index);

  // const handleChange = (e) => {
  //   setChecked(e.target.checked);
  // };

  return (
    <Box sx={{ mb: 7 }}>
      {/* <Box sx={{ m: 1 }}>
        <CsSwitch
          onTitle={"차트로 보기"}
          offTitle={""}
          checked={checked}
          handleChange={handleChange}
        />
      </Box> */}
      <Box sx={{ height: 500, m: 0, my: 2 }}>
        {(
          <DataGridPro
            columns={gridColumns}
            rows={values}
            density="compact"
            disableSelectionOnClick
            // components={{ Toolbar: CSToolbar }}
            getRowId={(row) => row.index}
            hideFooter
            initialState={{ pinnedColumns: { left: ['index'] } }}
          />
        )}
        {/*{checked && <ReturnBarChart chartValue={yearlyReturn} />}*/}
      </Box>
    </Box>
  );
}
