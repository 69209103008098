import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { green } from "@mui/material/colors";

type LoadingButtonProps = ButtonProps & { loading?: boolean };

export default function LoadingButton({
  children,
  onClick,
  loading = false,
  size = "medium",
  disabled,
  ...params
}: LoadingButtonProps) {
  return (
      <Button
        variant="contained"
        disabled={loading || disabled}
        onClick={onClick}
        size={size}
        {...params}
      >
        {children}
        {loading && (
          <CircularProgress
            size={20}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </Button>
  );
}
