import ReactDOM from 'react-dom';
import { useSnackbar, SnackbarProvider, VariantType } from "notistack";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
import MIconButton from "@components/button/MIconButton";

export default function SnackbarUI(
  msg,
  variant,
  enqueueSnackbar,
  closeSnackbar
) {
  return enqueueSnackbar(msg, {
    variant: variant,
    action: (key) => (
      <MIconButton size="medium" onClick={() => closeSnackbar(key)}>
        <Icon icon={closeFill} />
      </MIconButton>
    ),
  });
}

export const useOpenSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const openSnackbar = (msg, variant) => {
    SnackbarUI(msg, variant, enqueueSnackbar, closeSnackbar);
  };
  
  return openSnackbar;
};

// add a <div> child to body under which to mount the snackbars
const mountPoint = document.createElement('div');
document.body.appendChild(mountPoint);

export const snackbar = {
  success: function(msg) {
    this.toast(msg, 'success');
  },
  warning: function(msg) {
    this.toast(msg, 'warning');
  },
  info: function(msg) {
    this.toast(msg, 'info');
  },
  error: function(msg) {
    this.toast(msg, 'error');
  },
  toast: function(msg, variant: VariantType = 'default') {
    const ShowSnackbar = ({ message }) => {
      const { enqueueSnackbar } = useSnackbar();
      enqueueSnackbar(message, { variant });
      return null;
    };
    ReactDOM.render(
      // see https://github.com/iamhosseindhv/notistack#snackbarprovider
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <ShowSnackbar message={msg} />
      </SnackbarProvider>,
      mountPoint
    );
  }
};