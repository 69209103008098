import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
import trash from "@iconify/icons-eva/trash-2-outline";
import close from "@iconify/icons-eva/close-fill";
import shoppingCart from "@iconify/icons-eva/shopping-cart-fill";
// material
import {
  Box,
  Paper,
  Tooltip,
  Divider,
  Typography,
  Stack,
  Badge,
} from "@mui/material";

// components
import {Button} from "@mui/material";
import MIconButton from "./button/MIconButton";
import Pagination from "@mui/material/Pagination";
import Checkbox from "@mui/material/Checkbox";
// import FormControlLabel from "@mui/material/FormControlLabel";

import { useOpenSnackbar } from "@hooks/useSnackbar";
// ----------------------------------------------------------------------

const PAGE_COUNT = 5;
const MAX_COUNT = 20;
const DRAWER_WIDTH = 260;
const AUTO_CHECK_ENABLE = false;

type CartAssetType = {
  assetId: string,
  name: string,
  ticker?: string,
}

type CartAssetList = CartAssetType[];

type CartMenuProps = {
  data: CartAssetList;
  onSubmit: (e) => void;
  onChange: (e) => void;
  marketSelect?: string;
};

const CartOpener = ({open, onClickToogle, size}) => {
  return (<Box
      sx={{
        p: 0.5,
        px: "4px",
        mt: -3,
        left: -44,
        top: "50%",
        color: "grey.800",
        position: "absolute",
        bgcolor: "common.white",
        borderRadius: "24px 0 16px 24px",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      }}
    >
      <Tooltip title="선택 목록">
        <Badge
          badgeContent={size}
          color="primary"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MIconButton
            color="inherit"
            onClick={onClickToogle}
            sx={{
              p: 0,
              width: 40,
              height: 40,
              transition: (theme) => theme.transitions.create("all"),
              "&:hover": { color: "primary.main", bgcolor: "transparent" },
            }}
          >
            <Icon
              icon={open ? closeFill : shoppingCart}
              width={20}
              height={20}
            />
          </MIconButton>
        </Badge>
      </Tooltip>
    </Box>)
}

const CartItem = ({ id, assetId, name, ticker, checked, onChange, onDelete }) => {
  const handleClick = () => {
    onChange(!checked, id);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete({ assetId, id });
  };

  return (
    <Box
      sx={{
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        height: 4,
        p: 1,
        pb: 8.3,
        borderRadius: "10px",
        cursor: "pointer",
        "&:hover": {
          opacity: 0.7,
        },
        transition: (theme) => theme.transitions.create("all"),
      }}
      onClick={handleClick}
    >
      <Stack spacing={1.5}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Tooltip title={name} placement="top-start">
            <Typography
              variant="subtitle2"
              style={{ marginBottom: 10, fontSize: 13, height: 15, overflow: "hidden" }}
            >
              {name}
            </Typography>
          </Tooltip>
          <MIconButton
            onClick={handleDelete}
            style={{ marginTop: "-10px", marginRight: "-5px" }}
          >
            <Icon icon={close} width={16} />
          </MIconButton>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography sx={{ color: "red", fontSize: 15, fontWeight: 550 }}>
            {ticker}
          </Typography>
          <Checkbox
            checked={checked}
            size="small"
            // onClick={handleClick}
            style={{ marginRight: "-7px" }}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

const CartHeader = ({ checkAll, onClear, onClose, onOffCheckAll }) => {
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ py: 0.5, pr: 1, pl: 2.5 }}
      >
        <Typography variant="subtitle1">선택된 종목</Typography>
        <Box>
          <Tooltip title={"전체삭제"} placement="left">
            <MIconButton onClick={onClear}>
              <Icon icon={trash} width={20} height={20} />
            </MIconButton>
          </Tooltip>
          <MIconButton onClick={onClose}>
            <Icon icon={closeFill} width={20} height={20} />
          </MIconButton>
        </Box>
      </Stack>
      <Divider />
      {/* <FormControlLabel
        control={
          <Checkbox
            checked={checkAll}
            onChange={(e) => onOffCheckAll(e.target.checked)}
          />
        }
        label="전체선택"
        labelPlacement="start"
        sx={{ pt: 0.5, pb: 0, pl: 1, pr: 3.5, justifyContent: "space-between", display:"flex" }}
      /> */}
    </>
  );
};


const CartFooter = ({ totalCount, onDelete, onSubmit, onPageChange }) => (
  <>
    <Divider variant="middle" />
    <Stack direction="column" alignItems="center" sx={{ pt: 1.5 }}>
      <Stack direction="row" spacing={3}>
        <Button onClick={() => onDelete()} variant="contained">
          선택 삭제
        </Button>
        <Button variant="contained" onClick={() => onSubmit()}>
          비교 하기
        </Button>
      </Stack>
      <Pagination
        count={Math.ceil(totalCount / PAGE_COUNT)}
        size="small"
        shape="rounded"
        onChange={(e, v) => {
          onPageChange(v);
        }}
        sx={{ placeContent: "center", display: "grid", pt: 1.5 }}
      />
    </Stack>
  </>
);


export function CartMenu({
  data,
  marketSelect,
  onSubmit,
  onChange,
}: CartMenuProps) {
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState<CartAssetList>([]);
  const [currentSelectedData, setCurrentSelectedData] = useState<CartAssetList>(
    []
  );
  const [page, setPage] = useState(1);
  const [checked, setChecked] = useState<string[]>([]);
  const [checkAll, setCheckAll] = useState(false);

  const openSnackbar = useOpenSnackbar();

  useEffect(() => {
    setChecked([]);
    setCheckAll(false);
  }, [marketSelect]);

  useEffect(() => {
    setCurrentSelectedData(
      selectedData.slice(
        PAGE_COUNT * (page - 1),
        PAGE_COUNT * (page - 1) + PAGE_COUNT
      )
    );
    if (selectedData.length === 0) {
      setOpen(false);
    }
  }, [selectedData, page]);

  useEffect(() => {
    const uniqueData = _.uniqBy(data, "assetId");
    const sliceData = uniqueData.slice(0, MAX_COUNT);
    const removeTicker = uniqueData.slice(MAX_COUNT).map((v) => v.assetId);
    let modifyChecked = checked.filter((v) => !removeTicker.includes(v));
    setSelectedData(sliceData);

    if (AUTO_CHECK_ENABLE) {
      const prevTicker = selectedData.map((v) => v.assetId);
      const diff = sliceData.filter((v) => !prevTicker.includes(v.assetId));
      const autoChecked = diff.map((v) => v.assetId);
      modifyChecked = modifyChecked.concat(autoChecked);
    }
    setChecked(modifyChecked);
  }, [data]);

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckChange = (value, id) => {
    if (value === true) {
      if (checked.length >= 12) {
        openSnackbar("비교 자산을 12개 이하로 선택해주세요.", "warning");
      }
      else {
        setChecked([...checked, id]);
      }
    } else {      
        setChecked(checked.filter((v) => v !== id));
    }
  };

  const handleDelete = (checkedName) => {
    const newSelectedData = data.filter((v) => !checkedName.includes(v.assetId));
    onChange(newSelectedData);
    setChecked([]);
    setCheckAll(false);
  };

  const handleDeleteIndividual = (event) => {
    const filteredData = data.filter((v) => v["assetId"] !== event.assetId);
    const filteredCheckedData = checked.filter((v) => v !== event.id);

    onChange(filteredData);
    setChecked(filteredCheckedData);
  };

  const handleClear = () => {
    if (data.length === 0) {
      return;
    }
    if (window.confirm("전체 삭제하시겠습니까?")) {
      onChange([]);
      setChecked([]);
    }
  };

  const handleToggleAllChecked = (e) => {
    if (e === true) {
      const allChecked = data.map((v) => v.assetId);
      setChecked(allChecked);
    } else {
      setChecked([]);
    }
    setCheckAll(e);
  };

  return (
      <Box
        sx={{
          top: 0,
          bottom: 0,
          right: 0,
          position: "fixed",
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}
      >
        <CartOpener
          open={open}
          onClickToogle={handleToggle}
          size={selectedData.length}
        />
        <Paper
          sx={{
            height: "100%",
            width: "0px",
            overflow: "scroll",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            transition: (theme) => theme.transitions.create("width"),
            ...(open && { width: DRAWER_WIDTH }),
          }}
        >
          <CartHeader
            checkAll={checkAll}
            onClear={handleClear}
            onClose={handleClose}
            onOffCheckAll={handleToggleAllChecked}
          />
          <Stack
            direction="column"
            spacing={2}
            sx={{
              pt: 1,
              px: 3,
              height: "460px",
              position: "relative",
            }}
          >
            {currentSelectedData?.map((data, i) => (
              <CartItem
                key={data.assetId}
                id={data.assetId}
                assetId={data.assetId}
                name={data.name}
                ticker={data.ticker}
                checked={checked.includes(data.assetId)}
                onChange={handleCheckChange}
                onDelete={(e) => handleDeleteIndividual(e)}
              />
            ))}
          </Stack>
          <CartFooter
            onDelete={(e) => handleDelete(checked)}
            onSubmit={() => onSubmit(checked)}
            totalCount={selectedData.length}
            onPageChange={setPage}
          />
        </Paper>
      </Box>
  );
}

export default React.memo(CartMenu);