import { useRecoilValue } from 'recoil';
import { AuthType } from "types/auth";
import { authStateAtom } from '@states/auth';


// ----------------------------------------------------------------------
const useAuth = () => {
  const authInfo =  useRecoilValue<AuthType>(authStateAtom);
  return authInfo;
};

export default useAuth;
