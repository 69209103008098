import { IBacktestStaticStrategy } from "types/backtest";

export const REBAL_PARAM_DEFAULT = {
  no: {},
  regular: { rebal_freq: "M" },
  weight_threshold: {
    asset_change_threshold: 0.08,
    total_change_threshold: 10.0,
  },
};

export const RECON_PARAM_DEFAULT = {
  no: {},
  regular: { reconstruct_freq: 12 },
  target_month: { target_month: 4 },
};

export const SWITCHING_STRATEGY_DEFAULT = {
  name: '',
  type: 'switching',
  macroTicker: null,
  macroBoundary: 50,
  upperAssets: [{ id: '', name: '', weight: 0 }],
  lowerAssets: [{ id: '', name: '', weight: 0 }],
};

export const STATIC_STRATEGY_DEFAULT: IBacktestStaticStrategy = {
  name: '',
  assets: [],
  rebal_method: "None",
};

export const STATIC_STRATEGY_DEFAULT_3: IBacktestStaticStrategy = {
  name: '',
  assets: [{ id: '', name: '', ticker: '', weight: 0 }],
  rebal_method: "None",
};

export const MOMENTUM_STRATEGY_DEFAULT = {
  name: '',
  type: 'relative_momentum',
  assets: [{ id: '', name: '' }],
  rebalancing: {
    type: 'no',
    params: REBAL_PARAM_DEFAULT.no,
  },
  reconstruction: {
    type: 'no',
    params: RECON_PARAM_DEFAULT.no,
  },
  momentumPeriod: 12,
};
