import { createContext, useEffect } from "react";
import { useRecoilState } from "recoil";

import { authStateAtom } from '@states/auth';
import { hasActiveEliteMembership, hasActiveProMembership } from '@utils/auth';
import axios from "@utils/axios";
import { isValidToken, setSession } from "@utils/jwt";
import { UserType } from '../types/auth';

const AuthContext = createContext({});

function AuthProvider({ children }) {
  const [authStates, setAuthStates] = useRecoilState(authStateAtom);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get("users/me");
          const user: UserType = response.data;

          setAuthStates({
            isInitialized: true,
            isAuthenticated: true,
            hasProMembership: user.isSuperuser || hasActiveProMembership(user.memberships),
            hasEliteMembership: user.isSuperuser || hasActiveEliteMembership(user.memberships),
            user,
          });
        } else {
          setAuthStates({
            isInitialized: true,
            isAuthenticated: false,
            hasProMembership: false,
            hasEliteMembership: false,
            user: null,
          });
        }
      } catch (err) {
        console.error(err);
        setAuthStates({
          isInitialized: true,
          isAuthenticated: false,
          hasProMembership: false,
          hasEliteMembership: false,
          user: null,
        });
      }
    };
    initialize();
  }, [setAuthStates])

  // const register = async (email, password, firstName, lastName) => {
  //   const response = await axios.post("user/signup", {
  //     email,
  //     password,
  //     firstName,
  //     lastName,
  //   });
  //   const { accessToken, user } = response.data;
  //
  //   window.localStorage.setItem("accessToken", accessToken);
  //
  //   setAuthStates({
  //     isInitialized: true,
  //     isAuthenticated: true,
  //     user: user,
  //   });
  // };

  const register = () => {};

  const logout = async () => {
    setSession(null);

    setAuthStates({
      isInitialized: true,
      isAuthenticated: false,
      hasProMembership: false,
      hasEliteMembership: false,
      user: null,
    });
  };

  const resetPassword = () => {};

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...authStates,
        method: "jwt",
        logout,
        register,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
