import { GridColumns } from "@mui/x-data-grid-pro";
import {formatComma} from "@utils/formatNumber";

export const toCommaNumber = (param) => formatComma(param.value)

export const toFixedNumber = ({ value }) => {
	if (value)
		return Number(value).toLocaleString(undefined, {minimumFractionDigits: 2})
	else
		return ""
}

export const percentChange = ({ value }) => {
	if (value)
		return (Number(value)*100).toFixed(2) + " %"
	else
		return ""
}

// prettier-ignore
export const COLUMNS = {
	FUNDKR: [
		{ field: "ticker", headerName: "ticker", width: 150, disableReorder: true  },
		{ field: "종목명", headerName: "종목명", width: 350, disableReorder: true  },
		{ field: "데이터상태", headerName: "데이터상태",width: 100, valueFormatter: ({value})=>{if(value===1){return "데이터 누락"}else{return""}}},
		{ field: "설정일", headerName: "설정일", width: 150},
		{ field: "기준가격", headerName: "기준가격", width: 150, valueFormatter: toFixedNumber, align:"right"},
		{ field: "설정원본(백만원)", headerName: "설정원본(백만원)", width: 150, valueFormatter: toFixedNumber, align:"right"},
		{ field: "클래스분류", headerName: "클래스분류", width: 150},
		{ field: "실부담비용률", width: 150, valueFormatter: toFixedNumber, align:"right"},
		{ field: "총보수(A)", width: 150, valueFormatter: toFixedNumber, align:"right"},
		{ field: "TER(A+B)", width: 150, valueFormatter: toFixedNumber, align:"right"},
		{ field: "기준일", headerName: "기준일", width: 150},
		{ field: "구분", headerName: "구분", width: 150},
		{ field: "과표기준가격", headerName: "과표기준가격", width: 150, valueFormatter: toFixedNumber, align:"right"},
		{ field: "특성분류", headerName: "특성분류", width: 150},
		{ field: "펀드유형", headerName: "펀드유형", width: 150},
		{ field: "투자지역", headerName: "투자지역", width: 150},
		{ field: "투자지역구분", headerName: "투자지역구분", width: 150},
		{ field: "판매지역구분", headerName: "판매지역구분", width: 150},
		{ field: "공모/사모구분", headerName: "공모/사모구분", width: 150},
		{ field: "선취수수료", valueFormatter: toFixedNumber, align:"right"},
		{ field: "후취수수료", valueFormatter: toFixedNumber, align:"right"},
		{ field: "헷지여부"},
		{ field: "위험등급", align:"right", width: 150},
		{ field: "환매수수료정보"},
		{ field: "회사코드", headerName: "회사코드", width: 150},
		{ field: "신탁회계기간", headerName: "신탁회계기간", align:"right", width: 150},
		{ field: "분류코드", headerName: "분류코드", width: 150},
		{ field: "운용회사", headerName: "운용회사", width: 150},
		{ field: "운용종료일", headerName: "운용종료일", width: 150},
		// { field: "memo", headerName: "memo", width: 150},
	],
	INDEXGLOBAL: [
		{ field: "ticker", width: 100, disableReorder: true  },
		{ field: "종목명", width: 350, disableReorder: true  },
		{ field: "Asset Type", width: 150},
		{ field: "currency", width: 150},
		// { field: "출처", width: 150},
		// { field: "original_ticker", width: 150},
		{ field: "설명", width: 350},
	],
	INDEXMACRO: [
		{ field: "ticker", width: 150, disableReorder: true  },
		// { field: "종목명", width: 350, disableReorder: true  },
		{ field: "종목명", width: 350 },
		{ field: "name_en", haderName: "Name",  width: 350 },
		{ field: "freq", width: 150},
		{ field: "source", width: 150 },
		{ field: "unit", width: 150},
		{ field: "설명", width: 350},
		{ field: "rf_id"}
	],
	INDEXMSCI: [
		{ field: "ticker", width: 150, disableReorder: true  },
		// { field: "종목명", width: 350, disableReorder: true  },
		{ field: "종목명", width: 350 },
		{ field: "country", haderName: "국가",  width: 350 },
		{ field: "item_name", width: 150},
		{ field: "field", width: 150 },
		{ field: "rf_id"}
	],
	REALTYINDEX: [
		{ field: "ticker", width: 200, disableReorder: true  },
		{ field: "asset_name", width: 350, disableReorder: true  },
	],
	PROXY: [
		{ field: "rf_id", width: 90, disableReorder: true  },
		{ field: "undl_rf_id", width:100},
		{ field: "name", width: 230 },
		{ field: "undl_name", width: 150 },
		{ field: "undl_source", width: 110},
		{ field: "lagging", width:70, align:"right"},
		{ field: "ter", width:100, align:"right"},
		{ field: "undl_has_daily", width:120},
		{ field: "has_daily", width: 100 },
		{ field: "multiple", width:90, align:"right"},
		{ field: "to_currency", width:100},
		{ field: "source_ticker", width: 120},
		{ field: "source", width:100},
	],
	ProxyCompare: [
		{ field: "type", headerName: "종목", width: 250, disableReorder: true},
		{ field: "s_cagr", headerName: "상장 이후 연환산 수익률", width: 180, valueFormatter: percentChange, disableReorder: true},
		{ field: "s_vol", headerName: "상장 이후 연환산 변동성", width: 180, valueFormatter: percentChange, disableReorder: true},
		{ field: "w_cagr", headerName: "전체 기간 연환산 수익률", width: 180, valueFormatter: percentChange, disableReorder: true},
		{ field: "w_vol", headerName: "전체 기간 연환산 변동성", width: 180, valueFormatter: percentChange, disableReorder: true},
	]
	
};


export const AssetViewerExtraUndlInfoColumns = [
	{ field: "undlRfId", headerName: "기초지수 ID", width: 150},
	{ field: "multiple", headerName: "배수", width: 150},
	{ field: "lagging", headerName: "래깅", width: 150},
	{ field: "currency", headerName: "환율", width: 150},
];
  


export const ETFUSSIMPLE: GridColumns  = [
	{ field: "ticker", headerName: "ticker", width: 100, disableReorder: true  },
	{ field: "종목명", headerName: "종목명", width: 500 , disableReorder: true  },
	// { field: "기초지수(블룸버그)", headerName: "기초지수(블룸버그)", width: 150},
	{ field: "총보수", headerName: "총보수", width: 100, valueFormatter: toFixedNumber, align:"right"},
	{ field: "inception", headerName: "상장일", width: 150},
	// { field: "추적배수", headerName: "추적배수", width: 100, align:"right" },
	// { field: "환헤지", headerName: "환헤지", width: 150 },
	// { field: "복제방법", headerName: "복제방법", width: 150 },
	// { field: "memo", headerName: "memo", width: 150 },	
]

export const ETFUSEXTRA: GridColumns = [

	{ field: "Asset Class", headerName: "Asset Class", width: 150, },
	{ field: "asset_class", headerName: '자산군' },
	
	{ field: "issuer", headerName:  '발행사' },
	{ field: "brand", headerName:  '브랜드' },
	{ field: "structure", headerName: '구조' },
	{ field: "expense_ratio", headerName: '운용보수' },
	{ field: "inception", headerName: '상장일' },
	{ field: "index_tracked", headerName: '추종지수' },
	{ field: "category_themes", headerName: '테마 분류' },
	{ field: "asset_class_size", headerName: '자산군 규모' },
	{ field: "asset_class_style", headerName: '자산군 스타일' },
	{ field: "region_general", headerName: '지역(일반)' },
	{ field: "region_specific", headerName: '지역(상세)' },
	{ field: "segment", headerName: '부문' },
	{ field: "category_factset", headerName: '팩트셋 분류' },
	{ field: "focus", headerName: '초점' },
	{ field: "niche", headerName: '맞춤 공략' },
	{ field: "strategy", headerName: '전략' },
	{ field: "weighting_scheme", headerName: '가중치' },

	{ field: "확장지수코드", headerName: "기초지수코드", width: 150 },
	{ field: "기준일", headerName: "기준일", width: 150, },
	{ field: "시가", headerName: "시가", valueFormatter: toFixedNumber , width: 150, align:"right" },
	{ field: "고가", headerName: "고가", valueFormatter: toFixedNumber , width: 150, align:"right" },
	{ field: "저가", headerName: "저가", valueFormatter: toFixedNumber , width: 150, align:"right" },
	{ field: "종가", headerName: "종가", valueFormatter: toFixedNumber , width: 150, align:"right" },
	{ field: "거래량(20영업일평균)", headerName: "거래량(20영업일평균)", width: 150, valueFormatter: toFixedNumber, align:"right", },
	
	{ field: "sector_general", headerName: '섹터(일반)' },
	{ field: "sector_specific", headerName: '섹터(상세)' },
	{ field: "bond_types", headerName: '채권 유형' },
	{ field: "bond_duration", headerName: '채권 듀레이션' },
	{ field: "commodity_type", headerName: '상품 유형' },
	{ field: "commodity", headerName: '상품' },
	{ field: "commodity_exposure", headerName: '상품 익스포져' },
	{ field: "leveraged", headerName: '레버리지' },
	{ field: "currency", headerName: '통화' },
]

export const ETF_LISTING_COLUMNS : GridColumns = [
	{ field: "ticker", headerName: "Ticker", width: 80 , disableReorder: true },
	{ field: "name", headerName: "종목명", width: 300, disableReorder: true },
	{ field: "indexTracked", headerName: "기초지수명", width: 200},
	{ field: "expenseRatio", headerName: "실부담비용률(%)", width: 150, valueFormatter: toFixedNumber, align:"right"},
	{ field: "listingDt", headerName: "상장일", width: 120},
	// { field: "기초지수통화", width: 150},
	{ field: "aum", headerName: "순자산가치(억원)", width: 120, valueFormatter: toFixedNumber, align:"right"},
	{ field: "averageVolume", headerName: "거래량(3달평균)", width: 120, valueFormatter: toCommaNumber, align:"right"},
]


export const ETFKRSIMPLE : GridColumns = [
	{ field: "ticker", headerName: "Ticker", width: 80 , disableReorder: true },
	{ field: "name", headerName: "종목명", width: 300, disableReorder: true },
	{ field: "indexTracked", headerName: "기초지수명", width: 200},
	{ field: "expenseRatio", headerName: "실부담비용률(%)", width: 150, valueFormatter: toFixedNumber, align:"right"},
	{ field: "listingDt", headerName: "상장일", width: 120},
	// { field: "기초지수통화", width: 150},
	{ field: "aum", headerName: "순자산가치(억원)", width: 120, valueFormatter: toFixedNumber, align:"right"},
	{ field: "averageVolume", headerName: "거래량(3달평균)", width: 120, valueFormatter: toCommaNumber, align:"right"},
]

export const ETFKREXTRA: GridColumns = [
	{ field: "복제방법", headerName: "복제방법", width: 150},
	{ field: "확장지수코드", headerName: "기초지수코드", width: 150},
	{ field: "전체이름", headerName: "전체이름", width: 150},
	{ field: "운용사", headerName: "운용사", width: 150},
	{ field: "과세유형", headerName: "과세유형", width: 150},
	{ field: "지수산출기관", headerName: "지수산출기관", width: 150},
	{ field: "기초시장", headerName: "기초시장", width: 150},
	{ field: "분류", headerName: "분류", width: 150},
	{ field: "분야", headerName: "분야", width: 150},	
	{ field: "시가", headerName: "시가", width: 150, valueFormatter: toCommaNumber, align:"right"},
	{ field: "고가", headerName: "고가", width: 150, valueFormatter: toCommaNumber, align:"right"},
	{ field: "저가", headerName: "저가", width: 150, valueFormatter: toCommaNumber, align:"right"},
	{ field: "종가", headerName: "종가", width: 150, valueFormatter: toCommaNumber, align:"right"},
	{ field: "환헤지", headerName: "환헤지", width: 150},
	{ field: "isin", headerName: "isin", width: 150},
	{ field: "추적배수", headerName: "추적배수", width: 150, align:"right"},
	{ field: "추적오차", headerName: "추적오차", width: 150, valueFormatter: toFixedNumber, align:"right"},
	{ field: "괴리율", headerName: "괴리율", width: 150, align:"right"},
	// { field: "memo", headerName: "memo", width: 150},
	{ field: "순자산가치(NAV)", headerName: "순자산가치(NAV)", width: 150, valueFormatter: toFixedNumber, align:"right"},
	{ field: "순자산총액(AUM, 억원)", headerName: "순자산총액(AUM, 억원)", width: 170, valueFormatter: toFixedNumber, align:"right"},
	{ field: "거래량(20영업일평균)", headerName: "거래량(20영업일평균)", width: 150, valueFormatter: toFixedNumber, align:"right"},
	{ field: "기준일", headerName: "기준일", width: 150},
	{ field: "연평균분배금(3Y)", headerName: "연평균분배금(3Y)", width: 150, align:"right"},
	{ field: "asset_class", width: 150},
	{ field: "asset_types", width: 150},
	{ field: "bond_duration", headerName: "채권 듀레이션", width: 150},
	{ field: "target_duration", headerName: "목표 듀레이션", width: 150},
	{ field: "asset_size", width: 150},
	{ field: "commodity", width: 150},
	{ field: "theme", width: 150},
	{ field: "sector_general", width: 150},
	{ field: "sector_specific", width: 150},
	{ field: "strategy", width: 150},
	// { field: "TR", width: 150},		
]


export const STOCKKR: GridColumns  = [
	{ field: "ticker", width: 100, disableReorder: true  },
	{ field: "종목명", width: 300 , disableReorder: true  },
	{ field: "상장일", width: 150},
	{ field: "마켓", width: 150},
	{ field: "산업", width: 150},
	// { field: "memo",  width: 150 },	
]

export const STOCKUS: GridColumns  = [
	{ field: "ticker", width: 100, disableReorder: true  },
	{ field: "종목명", width: 300 , disableReorder: true  },
	{ field: "상장일", width: 150},
	{ field: "마켓", width: 150},
	// { field: "memo",  width: 150 },	
]

export const STOCK_LISTING_COLUMNS : GridColumns = [
	{ field: "ticker", headerName: "Ticker", width: 80 , disableReorder: true },
	{ field: "name", headerName: "종목명", width: 300, disableReorder: true },
	{ field: "listingDt", headerName: "상장일", width: 120},
	{ field: "market", headerName: "마켓", width: 100},
	{ field: "industry", headerName: "산업", width: 150},
	// { field: "aum", headerName: "시가총액(억원)", width: 150, valueFormatter: toFixedNumber, align:"right"},
	{ field: "averageVolume", headerName: "거래량(3달평균)", width: 120, valueFormatter: toCommaNumber, align:"right"},
]

export const ETFKR  = ETFKRSIMPLE.concat(ETFKREXTRA);

export const ETFUS  = ETFUSSIMPLE.concat(ETFUSEXTRA);