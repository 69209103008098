import { useState } from 'react';
import { useRecoilValue } from "recoil";
import dayjs from "dayjs";
import { perc2colorRange } from "@utils/color";
import styled from "@emotion/styled";
import SimpleYTDChart from "@components/chart/SimpleYTDChart";
import { AnalysisTableType, assetCompareYTDState } from "@states/asset";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'


function celRenderer(key, value, min=0, max=0) {
  const style = {
    backgroundColor: perc2colorRange(value, min, max),
    fontWeight:"bold",
    
  };
  return <TableCell key={key} style={style} align="right">{value}</TableCell>
}

const groupByYear = (values, name) => {
  const yearGroup = {};

  values.forEach((row) => {
    const year = dayjs(row.index).year();
    const month = dayjs(row.index).month() + 1;

    if (!yearGroup[year]) {
      yearGroup[year] = { index: year };
    }

    if (row[name] === undefined) {
      yearGroup[year][month] = "";
    } else {
      yearGroup[year][month] = (row[name] * 100).toFixed(2);
    }
  });
  return yearGroup;
};

const tdStyle = {borderBottom:"1px solid #ccc",borderRight:"1px solid #ccc" };

const SliceChartContainer = styled.div`
    height: 250px;
    background: white;
`

const YTDSliceChart = ({ show, data }) => {
  const {index, ...values } = data;
  const chartData = { 
    index:Object.keys(values), 
    values: [{
      name: index,
      data: Object.values(values).map( (v:any) => parseFloat(v))
    }]
  } 
  if (!show) return null;

  return (
    <tr>
      <td style={tdStyle} ></td>
      <td colSpan={12}>
          <SliceChartContainer>
            <SimpleYTDChart data={chartData} height="250"/>
          </SliceChartContainer>
      </td>
    </tr>
  );
};


function ColorTable({rows, min=0, max=0}) {
  const head = Array.from({length:12}).map((v,i)=>i+1)
  const [activeChart, setActiveChart] = useState<null | number>(null);

  const handleRowClick = (selectYear) => {
    if (activeChart === selectYear) {
      setActiveChart(null);
    } else {
      setActiveChart(selectYear);
    }
  }

  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table sx={{ width: "100%" }} size="small" aria-label="a dense table">
        <TableHead>
          <TableCell align="center">Year</TableCell>
          {head.map((month, i) => (
            <TableCell align="center" key={i}>
              {month}
            </TableCell>
          ))}
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <>
              <TableRow
                key={row.name}
                onClick={() => {
                  handleRowClick(row.index);
                }}
              >
                <TableCell
                  align="center"
                  style={{
                    position: "sticky",
                    left: 0,
                    background: "white",
                    zIndex: 100,
                  }}
                >
                  {row.index}
                </TableCell>
                {head.map((month, headIndex) =>
                  celRenderer(headIndex, row[month], min, max)
                )}
              </TableRow>
              <YTDSliceChart show={activeChart === row.index} data={row} />
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


export default function YTDTable() {
  const ytdValue = useRecoilValue(assetCompareYTDState);

  if (!ytdValue) {
    return <></>;
  }

  const { values, index }: AnalysisTableType = ytdValue;
  const name = index[0];
  const currentValues = values.map((v)=>v[name]);
  const max = Math.max(...currentValues) * 100;
  const min = Math.min(...currentValues) * 100;
  const yearGroup = groupByYear(values, name);
  const value: Array<Object> = Object.values(yearGroup);
  
  return <ColorTable rows={value} min={min} max={max}/>;
}