import { useEffect, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { useOpenSnackbar } from "@hooks/useSnackbar";
import { UndlContent } from "@pages/asset/detail/UndlContent";
import { AssetViewerExtraUndlInfoColumns } from "@pages/asset/define";
import { etfChartCurrencyState, etfChartDataState, etfChartDateRangeState, etfSelectedTickerState } from "@states/etf";

function UndlPanel() {
  const selectedAsset = useRecoilValue(etfSelectedTickerState);
  const etfChartData = useRecoilValue(etfChartDataState);
  const [chartDateRange, setChartDateRange] = useRecoilState(etfChartDateRangeState);
  const [chartCurrency, setChartCurrency] = useRecoilState(etfChartCurrencyState);

  const market = useMemo(() => {
    if (selectedAsset?.assetId.startsWith("EU"))
      return "us"
    else
      return "kr"
  }, [selectedAsset]);

  useEffect(() => {
    if (market === "us")
      setChartCurrency("USD");
    else
      setChartCurrency("KRW");
  }, [market, setChartCurrency]);

  const openSnackbar = useOpenSnackbar();

  useEffect(() => {
    if (!etfChartData) {
      openSnackbar("데이터가 없습니다. 😰", "error");
    } else return;
  }, [etfChartData, openSnackbar]);

  if (!selectedAsset || !etfChartData)
    return <></>;

  return (
    <UndlContent
      columns={AssetViewerExtraUndlInfoColumns}
      proxyChartData={etfChartData}
      market={market}
      selectedAsset={selectedAsset}
      dateRange={chartDateRange}
      onChangeDateRange={setChartDateRange}
      currency={chartCurrency}
      onChangeCurrency={setChartCurrency}
    />
  );
}

export default UndlPanel;
