import {
  useRecoilState,
  useRecoilRefresher_UNSTABLE,
  useRecoilValueLoadable,
  Loadable,
} from "recoil";
import { useEffect, useState } from "react";
import moment from "moment";
import {
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import { DataGridPro, GridColDef, gridClasses } from "@mui/x-data-grid-pro";
import {
  staticBacktestHistoriesQueryPage,
  staticBacktestHistoriesQueryPageSize,
  staticBacktestHistoriesQuery,
} from "@states/staticBacktest";
import useAuth from "@hooks/useAuth";
import axios from "@utils/axios";
import { IPagination } from "types/api";
import { IResBacktestHistory } from "types/backtest";

export default function BacktestHistoryDialog({ open, onSelect, onClose }) {
  const { user: me } = useAuth();
  const [page, setPage] = useRecoilState(staticBacktestHistoriesQueryPage);
  const [pageSize, setPageSize] = useRecoilState(staticBacktestHistoriesQueryPageSize);

  const backtestHistoriesLoadable: Loadable<IPagination<IResBacktestHistory>> = useRecoilValueLoadable<IPagination<IResBacktestHistory>>(
    staticBacktestHistoriesQuery
  );
  const backtestHistories: IPagination<IResBacktestHistory> = backtestHistoriesLoadable.contents;

  //https://mui.com/x/react-data-grid/pagination/#basic-implementation
  const [rowCountState, setRowCountState] = useState(
    backtestHistories.total || 0,
  );
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
    backtestHistories.total !== undefined
        ? backtestHistories.total
        : prevRowCountState,
    );
  }, [backtestHistories.total, setRowCountState]);

  const backtestHistoriesRefresher = useRecoilRefresher_UNSTABLE(staticBacktestHistoriesQuery);

  const backtestHistoryColumns: GridColDef[] = [
    // { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "이름", width: 150 },
    { field: "bt_start", headerName: "시작날짜", width: 100, valueGetter: (params) => params.row.config.bt_start },
    { field: "bt_end", headerName: "종료날짜", width: 100, valueGetter: (params) => params.row.config.bt_end },
    {
      field: "strategies-assets",
      headerName: "전략-포트폴리오",
      width: 300,
      renderCell: (params) => (
        <List className="flex"
          sx={{
            width: '100%',
            maxWidth: 360,
            // bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 300,
            '& ul': { padding: 0 },
          }}
          subheader={<li />}
        >
          {params.row.config.strategies.map((strategy, index) => (
            <ListItem key={`st-${index}`}>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <Typography noWrap>{`${strategy.name}: `}</Typography>
                {strategy.assets.map((asset) => (
                  <Chip label={`${asset.name}: ${asset.weight}%`} size="small" variant="outlined"/>
                ))}
              </Stack>
            </ListItem>
          ))}
        </List>
      ),
    },
    {
      field: "data_freq",
      headerName: "주기",
      width: 70,
      type: "string",
      renderCell: (params) => (
        <Chip
          label={params.row.config.data_freq}
          size="small"
          color="primary"
          variant="outlined"
        />
      ),
    },
    {
      field: "currency",
      headerName: "통화",
      width: 70,
      type: "string",
      renderCell: (params) => (
        <Chip
          label={(params.row.config.currency === "USD") ? "$" : "₩"}
          size="small"
          color="primary"
          variant="outlined"
        />
      ),
    },
    { field: "cost", headerName: "거래비용", width: 70, valueGetter: (params) => `${params.row.config.cost * 100}%` },
    { field: "benchmark", headerName: "벤치마크 지수", width: 120, valueGetter: (params) => params.row.config.benchmark },
    {
      field: "createdAt",
      headerName: "실행일시",
      width: 150,
      renderCell: (params) =>
        moment(new Date(params.value), "h:mm:ss A")
          .add(9, "hour")
          .format("YYYY-MM-DD HH:mm:ss"),
    },
    // { field: "user", headerName: "사용자", width: 150, type: "string" },
    ...[{
      field: "action",
      headerName: "삭제",
      width: 70,
      renderCell: (params) =>
        (params.row.userId === me?.id) ? (
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={(e) => {
              const deleteConfirm = window.confirm(
                `"${params.row.name}" 기록을 삭제하시겠습니까?`
              );
              if (deleteConfirm) {
                deleteBacktestHistory(params.row.id);
              }
              e.stopPropagation();
            }}
          >
            삭제
          </Button>
        ) : null,
    }],
  ];

  const deleteBacktestHistory = async (id: number) => {
    await axios.delete(`/backtest/histories/${id}`);
    backtestHistoriesRefresher();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={() => {
        setPage(0);
        onClose();
      }}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">내 백테스트 실행 기록</DialogTitle>
      <DialogContent style={{ height: 600, width: "100%" }}>
        <DataGridPro
          rows={backtestHistories.items || []}
          rowCount={rowCountState}
          loading={backtestHistoriesLoadable.state === "loading"}
          // rowsPerPageOptions={[5, 10, 50]}
          pagination
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          columns={backtestHistoryColumns}
          initialState={{
            pinnedColumns: { left: ["id"] }
          }}
          density="compact"
          // components={{ Toolbar: CSToolbar }}
          getRowId={(row) => row.id}
          onRowClick={(param, evt) => {
            setPage(0);
            onSelect(param.row.config);
          }}
          getEstimatedRowHeight={() => 200}
          getRowHeight={() => 'auto'}
          sx={{
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
          }}
        />
      </DialogContent>
    </Dialog>
  );
}
  