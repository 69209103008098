import React, { useCallback, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";

import { DateRange } from "@components/DateRange";
import { LoadingOveray } from "@components/LoadingScreen";
import { TabContents } from "@components/tab/TabPanel";
import { dateRangeBeginState, dateRangeEndState } from "@states/asset";
import { useLoading } from "@hooks/useLoading";
import { useOpenSnackbar } from "@hooks/useSnackbar";
import {
  selectAssetDiffData,
  assetCompareDetailState,
  assetCompareDateRangeState,
  // ASSET_COMPARISON_API,
} from "@states/asset";
import { DateRangeType } from "types/dateRange"; 

import Information from "./Information";
import LineChart from "./LineChart";
import Summary from "./Summary";
import YearReturn from "./YearReturn";
import DrawDown from "./DrawDown";
import Correlation from "./Correlation";
import TreeMap from "./TreeMap";

export const createTabData = (props) => {
  const { assetType } = props;
  let content = [
    {
      id: 0,
      component: <Information {...props} />,
      label: "기본정보",
    },
    {
      id: 1,
      component: <LineChart {...props} />,
      label: "가격 시계열",
    },
    {
      id: 2,
      component: <Summary {...props} />,
      label: "요약",
    },
    {
      id: 3,
      component: <YearReturn {...props} />,
      label: "연도별 성과",
    },
    {
      id: 4,
      component: <DrawDown {...props} />,
      label: "낙폭",
    },
    {
      id: 5,
      component: <Correlation {...props} />,
      label: "상관관계",
    },
  ];
  // if (ASSET_COMPARISON_API.includes(assetType)) {
  //   content.shift();
  // }
  if (assetType === "etf") {
    content = [
      ...content,
      {
        id: 6,
        component: <TreeMap {...props} />,
        label: "거래량",
      },
    ];
  }
  return content;
};

function DetailContents({ assetIds, assetType, enableDownload }) {
  return <TabContents tabs={createTabData({ assetIds, assetType, enableDownload })} />;
}

export function AssetDiffViewer({ assetType, assetIds, show, enableDownload, onClose }) {
  const setAssetCompareDetail = useSetRecoilState(assetCompareDetailState);
  const setAssetCompareDateRange = useSetRecoilState(
    assetCompareDateRangeState
  );
  const setBegin = useSetRecoilState(dateRangeBeginState);
  const setEnd = useSetRecoilState(dateRangeEndState);
  const [loading, setLoading] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [date, setDate] = useState<DateRangeType>({ begin: null, end: null });

  const fetcher = async () => {
    const data = await selectAssetDiffData(
      assetIds,
      "D",
      date.begin,
      date.end,
    );
    if (data.warning) {
      setWarningMessage(data.warning);
    } else {
      setWarningMessage("");
    }
    setAssetCompareDetail(data);
  };

  const openSnackbar = useOpenSnackbar();
  const errorCallback = useCallback((e) => {
    if (e && e.detail) {
      openSnackbar(e.detail, "error");
      return;
    }
    openSnackbar("알수 없는 오류가 발생했습니다.", "error");
  }, []);

  const asyncFecther = useLoading(setLoading, fetcher, errorCallback);

  useEffect(() => {
    asyncFecther();
    setAssetCompareDateRange(date);
  }, [date]);

  useEffect(() => {
    setBegin(null);
    setEnd(null);
  }, []);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={show}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
    >
      <Grid
        container
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ py: 1, pr: 5, pl: 2.5 }}
      >
        <Grid item xs={6}>
          <DialogTitle sx={{ p: 0, pt: 1, pb: 0 }}>종목 비교 </DialogTitle>
        </Grid>
        <Grid container item spacing={1} direction="row" sx={{ mt: 2 }} md={4}>
          <DateRange onSubmit={setDate} checkValidationBeforeSubmit/>
        </Grid>
      </Grid>
      <div
        onClick={onClose}
        color="secondary"
        style={{ position: "absolute", right: "13px", top: "10px" }}
      >
        <Icon icon={closeFill} width={20} height={20} />
      </div>
      <Box>
        {warningMessage && (
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              color: "#ff5500",
              mb: 2,
              mr: 2,
              textAlign: "right",
              textDecoration: "underline",
            }}
          >
            {warningMessage}
          </Typography>
        )}
      </Box>
      <DialogContent style={{ height: 700, marginTop: 0, paddingTop: 0 }}>
        <DetailContents assetIds={assetIds} assetType={assetType} enableDownload={enableDownload} />
      </DialogContent>
      {loading && <LoadingOveray show={!loading} />}
    </Dialog>
  );
}

export default AssetDiffViewer;
