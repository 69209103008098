import axios from "./axios";
import { DateRangeType } from "../types/dateRange";

export async function downloadExcel(url: string, name: string, params = {}) {
  const response = await axios({
    method: "GET",
    url: url,
    responseType: "blob",
    params: params,
  });

  const href = window.URL.createObjectURL(
    new Blob([response.data], { type: response.headers["content-type"] })
  );
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", `${name}.xlsx`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export async function downloadExcelPost(
  url: string,
  name: string,
  params = {}
) {
  const response = await axios({
    method: "POST",
    url: url,
    responseType: "blob",
    data: params,
  });

  const href = window.URL.createObjectURL(
    new Blob([response.data], { type: response.headers["content-type"] })
  );
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", `${name}.xlsx`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
