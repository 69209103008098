import _ from "lodash";
import { Button, Card, Stack } from "@mui/material";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import producer from "immer";
import moment from "moment";

import LoadingButton from "@components/button/LoadingButton";
import { useOpenSnackbar } from "@hooks/useSnackbar";
import { authStateAtom } from "@states/auth";
import {
  staticBacktestBacktestDetailState,
  staticBacktestConfigState,
  staticBacktestLoadingState,
  staticBacktestStrategiesState,
} from "@states/staticBacktest";
import axios from "@utils/axios";
import { downloadExcelPost } from "@utils/download";
import { IBacktestStaticPortfolio, IBacktestStaticStrategy, IReqBacktestStatic } from "types/backtest";
import { STATIC_STRATEGY_DEFAULT } from "../../define";
import { useState } from "react";

export default function BacktestRunBtn() {
  const backtestConfig = useRecoilValue(staticBacktestConfigState);
  const authStates = useRecoilValue(authStateAtom);

  const backtestName = backtestConfig.name;
  const startDate = backtestConfig.bt_start;
  const endDate = backtestConfig.bt_end;
  const frequency = backtestConfig.data_freq;
  const benchmarkList = backtestConfig.benchmark;
  const costValue = backtestConfig.cost;
  const currency = backtestConfig.currency;
  const initialAmount = backtestConfig.initial_amount;

  const setBacktestLoading = useSetRecoilState(staticBacktestLoadingState);
  const [strategies, setStrategies] = useRecoilState(staticBacktestStrategiesState);
  const setBacktestDetail = useSetRecoilState(
    staticBacktestBacktestDetailState
  );
  const [isDownloadingBacktestResult, setIsDownloadingBacktestResult] = useState(false);

  const openSnackbar = useOpenSnackbar();

  const validationBacktestParam = () => {
    const ratioArray = strategies?.map((strategy) =>
      strategy.assets.map((v) => Number(v.weight))
        .reduce((acc, weight) => acc + weight, 0).toFixed(2)
    );
    console.log(ratioArray);
    if (strategies) {
      console.log(strategies[0].assets);
    }

    if (startDate === "" || endDate === "") {
      openSnackbar("백테스트 수행 날짜를 입력하세요.", "warning");
      return false;
    }

    if (backtestName === "") {
      openSnackbar("백테스트 이름을 입력해주세요.", "warning");
      return false;
    }

    if (costValue == null) {
      openSnackbar("백테스트 거래 수수료를 입력해주세요.", "warning");
      return false;
    }

    if (!moment(startDate, "YYYYMMDD", true).isValid()) {
      openSnackbar("백테스트 시작 날짜 형식이 맞지 않습니다.", "warning");
      return false;
    }

    if (!moment(endDate, "YYYYMMDD", true).isValid()) {
      openSnackbar("백테스트 시작 날짜 형식이 맞지 않습니다.", "warning");
      return false;
    }

    if (startDate < "20000101") {
      openSnackbar("백테스트 시작 날짜는 2000년 이후부터 가능합니다.", "warning");
      return false;
    }

    if (startDate >= endDate) {
      openSnackbar("백테스트 시작 날짜를 종료 날짜 이전으로 입력해주세요.", "warning");
      return false;
    }

    if (costValue < 0 || costValue >= 10) {
      openSnackbar("백테스트 거래 수수료는 0% 이상 10% 미만으로 입력해주세요.", "warning");
      return false;
    }

    if (!strategies || strategies.length === 0) {
      openSnackbar("백테스트할 전략을 추가해주세요.", "warning");
      return false;
    }

    const st_name_arr = strategies.map(st => st.name)
    if (new Set(st_name_arr).size !== st_name_arr.length) {
      openSnackbar("전략 이름은 동일할 수 없습니다. 전략 이름을 다르게 설정해주세요.", "warning");
      return false;
    }

    for (const strategy of strategies) {
      if (!strategy.name || strategy.name === "") {
        openSnackbar("전략 이름을 입력해주세요.", "warning");
        return false;
      }

      if (strategy.assets.length === 0) {
        openSnackbar("전략 내 자산이 한 개도 없습니다.", "warning");
        return false;
      }

      for (const asset of strategy.assets) {
        if (asset.id === "") {
          openSnackbar("지정되지 않은 종목이 있습니다. 종목을 삭제하시거나 지정해주세요.", "warning");
          return false;
        }
      }

      if (strategy.rebal_method === "Dynamic") {
        if (!strategy.threshold || strategy.threshold < 1) {
          openSnackbar("수시 리밸런싱 임계치를 1% 이상 정수로 입력해주세요", "warning");
          return false;
        }
      }
    }

    if (!ratioArray.every((sum) => sum == "100.00")) {
      openSnackbar("포트폴리오 종목 비율 합을 100%로 설정해주세요.", "warning");
      return false;
    }

    return true;
  };

  const to_port_list = (strategies: IBacktestStaticStrategy[]): IBacktestStaticPortfolio[] => {
    return strategies.map((strategy: IBacktestStaticStrategy): IBacktestStaticPortfolio => {
      const port: object = strategy.assets.reduce((acc, curr) => {
        acc[curr.id] = curr.weight * 0.01;
        return acc;
      }, {});

      let value: IBacktestStaticPortfolio = {
        name: strategy.name,
        port,
      };

      if (strategy.rebal_method === "None") {
        value = {
          name: strategy.name,
          port,
          rebal_method: strategy.rebal_method,
          cashflows: strategy.cashflows,
        }
      } else if (strategy.rebal_method === "Static") {
        value = {
          name: strategy.name,
          port,
          rebal_freq: strategy.rebal_freq,
          rebal_method: strategy.rebal_method,
          cashflows: strategy.cashflows,
        };
      } else if (strategy.rebal_method === "Dynamic") {
        value = {
          name: strategy.name,
          port,
          threshold: (strategy.threshold || 0) / 100,
          rebal_method: strategy.rebal_method,
          cashflows: strategy.cashflows,
        };
      }

      return value;
    });
  }

  const runBacktest = () => {
    if (!validationBacktestParam()) {
      return;
    }
    setBacktestLoading(true);
    const port_list = to_port_list(strategies);
    const req_body: IReqBacktestStatic = {
      name: backtestName,
      bt_start: startDate,
      bt_end: endDate,
      data_freq: frequency,
      cost: costValue / 100,
      initial_amount: initialAmount,
      benchmark: benchmarkList,
      port_list: port_list,
      strategies: strategies,
      currency: currency,
    };

    axios.post("backtest/static", req_body)
      .then((res) => setBacktestDetail(res.data))
      .catch((error) => {
        openSnackbar("백테스트 실행을 완료하지 못했습니다.", "warning");
      });
  };

  const downloadBacktestResult = async () => {
    if (!validationBacktestParam()) {
      return;
    }
    const port_list = to_port_list(strategies);
    const req_body: IReqBacktestStatic = {
      name: backtestName,
      bt_start: startDate,
      bt_end: endDate,
      data_freq: frequency,
      cost: costValue / 100,
      initial_amount: initialAmount,
      benchmark: benchmarkList,
      port_list: port_list,
      strategies: strategies,
      currency: currency,
    };

    const filename = `${backtestName} 백테스트 결과`;

    await downloadExcelPost("backtest/static/excel", filename, req_body);
  };

  const addStrategy = () => {
    if (strategies.length >= 5) {
      openSnackbar("전략은 최대 5개까지 추가 가능합니다.", "warning");
      return;
    }
    setStrategies((prevState) =>
      producer(prevState, (draft) => {
        draft.push(_.cloneDeep(STATIC_STRATEGY_DEFAULT));
      })
    );
  };

  return (
    <Card sx={{ p: 3 }}>
      <Stack
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button
          sx={{ width: "100%", height: 40 }}
          variant="outlined"
          onClick={addStrategy}
        >
          전략 추가
        </Button>
        <Stack sx={{ width: "100%", height: 40 }} spacing={2} direction="row">
          <Button
            variant="contained"
            onClick={runBacktest}
            size="medium"
            sx={{ width: "100%" }}
          >
            백테스트 실행
          </Button>
          {authStates.hasEliteMembership &&
            <LoadingButton
              color="success"
              loading={isDownloadingBacktestResult}
              onClick={async () => {
                setIsDownloadingBacktestResult(true);
                await downloadBacktestResult();
                setIsDownloadingBacktestResult(false);
              }}
              size="medium"
              sx={{ width: "100%" }}
            >
              백테스트 실행 결과 다운로드
            </LoadingButton>
          }
        </Stack>
      </Stack>
    </Card>
  );
}
