import { Box } from "@mui/material";
import { useRecoilValue } from "recoil";
import { assetCompareCorrelationState, assetCompareMonthlyCorrelationState } from "@states/asset";
import HeatmapChart from "@components/chart/HeatmapChart";
import { CsSwitch } from "@components/CsSwitch";
import { useMemo, useState } from "react";

type CorrelationType = { values: any[] } | undefined;

export default function Correlation() {
  const [checkedDaily, setChecked] = useState(true);
  const handleChange = (e) => {
    setChecked(e.target.checked)
  }
  const dailyCorrelation = useRecoilValue<CorrelationType>(
    assetCompareCorrelationState
  );

  const monthlyCorrelation = useRecoilValue<CorrelationType>(assetCompareMonthlyCorrelationState);

  const correlationState: CorrelationType = useMemo(() => {
    return checkedDaily ? dailyCorrelation : monthlyCorrelation
  }, [checkedDaily, dailyCorrelation, monthlyCorrelation])

  if (!correlationState) {
    return <></>;
  }

  const heatmap = correlationState.values.map(({ index, ...data }) => {
    return {
      name: index,
      data: Object.keys(data).map((k, i) => ({ x: k, y: data[k] })).reverse(),
    };
  });

  return (
    <>
      <Box sx={{ p: 1 }}>
        <CsSwitch onTitle={"일별"} offTitle={"월별"} checked={checkedDaily} handleChange={handleChange}/>
      </Box>
      <Box sx={{ minHeight: 500, m: 0, my: 0, width: "100%" }}>
        <HeatmapChart title="" data={heatmap}/>
      </Box>
    </>
  );
}
