import { useRecoilValue } from "recoil";
import { stockSelectedTickerState } from "@states/stock";
import { AssetTitle } from "@pages/asset/styles"
// import AssetMemo from '../../../detail/AssetMemo';

function Header({name="name"}) {
  const selectedTicker = useRecoilValue(stockSelectedTickerState);
  return (
    selectedTicker && (
      <>
        <AssetTitle
          ticker={selectedTicker.ticker}
          name={selectedTicker[name]}
        />
      </>
    )
  );
}

export default Header;
