import { Fragment, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import producer from "immer";
import {
  Autocomplete,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { Delete as DeleteIcon } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Popper from "@mui/material/Popper";

import { StyledLabel } from "@components/ui/styles";
import useAuth from "@hooks/useAuth";
import { useOpenSnackbar } from "@hooks/useSnackbar";
import { staticBacktestStrategiesState } from "@states/staticBacktest";
import {
  assetETFKRCatalogState,
  assetETFUSCatalogState,
  assetStockKRCatalogState,
  assetStockUSCatalogState
} from "@states/asset";

// export const FadeColorAnimate = styled.div`
//     @keyframes color_change {
//         from {
//             background-color: #aaaaaa33;
//         }
//         to {
//             background-color: none;
//         }
//     }
//
//     background-color: none;
//     -webkit-animation: color_change 1s;
//     -webkit-animation: color_change 1s;
//     -webkit-animation: color_change 1s;
//     -webkit-animation: color_change 1s;
//     -webkit-animation: color_change 1s;
// `;

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: "#eaeaef",
  },
});

const WeightInput = ({ value, onChange }) => {
  return (
    <TextField
      label="비율"
      size="small"
      sx={{ width: "100px" }}
      value={value}
      type="number"
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
      onChange={onChange}
    />
  );
};

const sumAsset = (strategy) => {
  const sum = strategy.assets
    .map((v) => Number(v.weight))
    .reduce((acc, val) => acc + val, 0)
    .toFixed(2);

  return ` ${sum} %`;
};

const StrategyAssetWeight = ({ strategy, strategyIndex, onOpenDialog }) => {
  const assetETFKRCatalog = useRecoilValue(assetETFKRCatalogState);
  const assetETFUSCatalog = useRecoilValue(assetETFUSCatalogState);
  const assetStockKRCatalog = useRecoilValue(assetStockKRCatalogState);
  const assetStockUSCatalog = useRecoilValue(assetStockUSCatalogState);
  const [strategies, setStrategies] = useRecoilState(staticBacktestStrategiesState);

  const { hasProMembership } = useAuth();
  const openSnackbar = useOpenSnackbar();

  const [inputValue, setInputValue] = useState("");
  const [assetCatalog, setAssetCatalog] = useState([]);
  const [assetType, setAssetType] = useState("ETF");
  const [assetCountry, setAssetCountry] = useState("KR");

  const handleAssetType = (
    event: React.MouseEvent<HTMLElement>,
    newAssetType: string,
  ) => {
    setAssetType(newAssetType);
  };

  const handleAssetCountry = (
    event: React.MouseEvent<HTMLElement>,
    newAssetCountry: string,
  ) => {
    setAssetCountry(newAssetCountry);
  };

  const getAssetsValue = (assetIndex, effector) => {
    setStrategies((prevState) =>
      producer(prevState, (draft) => {
        effector(
          draft,
          draft[strategyIndex].assets,
          draft[strategyIndex].assets[assetIndex]
        );
      })
    );
  };

  const strategyAssetIds = useMemo(() => {
    return strategies[strategyIndex].assets.map(asset => asset.id);
  }, [strategies, strategyIndex]);

  const handleAddSelectChange = () => (e, value) => {
    setInputValue("");

    // 기존 포트폴리오에 있는 종목인 경우 X
    if (value && value.assetId && strategyAssetIds.includes(value.assetId)) {
      openSnackbar("동일 종목은 추가할 수 없습니다.", "warning");
      return;
    }

    getAssetsValue(0, (strategy, assets, asset) => {
      if (value) {
        assets.push({ id: value.assetId, ticker: value.ticker, name: value.name, weight: 0 });
      }
    });
  };

  const handleWeightChange = (assetIndex) => (e) => {
    getAssetsValue(assetIndex, (strategy, assets, asset) => {
      asset.weight = e.target.value;
    });
  };

  const handleMoveIndex =
    (pos, dir = 1) =>
      (e) => {
        getAssetsValue(0, (strategy, assets, asset) => {
          const next = pos + dir;
          [assets[next], assets[pos]] = [assets[pos], assets[next]];
        });
      };

  const removeAsset = (assetIndex) => {
    getAssetsValue(assetIndex, (strategy, assets, asset) => {
      assets.splice(assetIndex, 1);
    });
  };

  useEffect(() => {
    if (assetType === "ETF" && assetCountry === "KR")
      setAssetCatalog(assetETFKRCatalog);
    else if (assetType === "ETF" && assetCountry === "US")
      setAssetCatalog(assetETFUSCatalog);
    else if (assetType === "STOCK" && assetCountry === "KR")
      setAssetCatalog(assetStockKRCatalog);
    else if (assetType === "STOCK" && assetCountry === "US")
      setAssetCatalog(assetStockUSCatalog);

    // handleSelectChange(-1);
    setInputValue("");

  }, [assetType, assetCountry, assetETFKRCatalog, assetETFUSCatalog, assetStockKRCatalog, assetStockUSCatalog]);

  return (
    <>
      <StyledLabel style={{ marginBottom: 10 }}>종목 구성</StyledLabel>
      <Grid container style={{ marginBottom: 10 }} spacing={1}>
        <Grid item md={2} xs={12}>
          <ToggleButtonGroup
            exclusive
            fullWidth
            size="small"
            value={assetType}
            onChange={handleAssetType}
          >
            <ToggleButton value="ETF">ETF</ToggleButton>
            <ToggleButton value="STOCK">주식</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        {(hasProMembership) ?
          <Grid item md={2} xs={12}>
            <ToggleButtonGroup
              exclusive
              fullWidth
              size="small"
              value={assetCountry}
              onChange={handleAssetCountry}
            >
              <ToggleButton value="KR">한국</ToggleButton>
              <ToggleButton value="US">미국</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          : null}
        <Grid item md={8} xs={12}>
          <Autocomplete
            key={`${assetType}-${assetCountry}`}
            size="small"
            sx={{ width: "100%", mb: 0.9 }}
            options={assetCatalog}
            value={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue("");
            }}
            renderInput={(params) => (
              <TextField {...params} label="종목을 선택해주세요"/>
            )}
            PopperComponent={StyledPopper}
            onChange={handleAddSelectChange()}
          />
        </Grid>
      </Grid>
      <Grid container alignContent="center" spacing={0.5}>
        {strategy.assets.map((asset, assetIndex) => (
          <Fragment key={assetIndex}>
            <Grid container spacing={1}>
              <Grid item md={8} xs={12} alignContent="center">
                <Typography>
                  {(asset && asset.ticker && asset.name) ? `[${asset.ticker}] ${asset.name}` : ""}
                </Typography>
              </Grid>
              <Grid container item md={4} xs={6} spacing={1} sx={{ pb: 1.5 }}>
                <Grid item>
                  <WeightInput
                    value={asset.weight}
                    onChange={handleWeightChange(assetIndex)}
                  />
                </Grid>
                <Grid item>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => removeAsset(assetIndex)}
                  >
                    <DeleteIcon/>
                  </IconButton>
                </Grid>
                <Grid item md={1} xs={1}>
                  <Stack direction="column">
                    <IconButton
                      sx={{ m: 0, p: 0 }}
                      style={{ marginLeft: "0px" }}
                      onClick={handleMoveIndex(assetIndex, -1)}
                      disabled={assetIndex === 0}
                    >
                      <KeyboardArrowUpIcon style={{ marginBottom: "-3px" }}/>
                    </IconButton>
                    <IconButton
                      sx={{ m: 0, p: 0 }}
                      style={{ marginLeft: "0px" }}
                      onClick={handleMoveIndex(assetIndex, +1)}
                      disabled={
                        strategies[strategyIndex].assets.length - 1 === assetIndex
                      }
                    >
                      <KeyboardArrowDownIcon style={{ marginTop: "-3px" }}/>
                    </IconButton>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Fragment>
        ))}
      </Grid>
      <Grid container spacing={1} style={{ marginTop: 10 }} direction="column">
        <Grid item>
          <StyledLabel>
            포트폴리오 종목 비율 합:
            {sumAsset(strategy)}
          </StyledLabel>
        </Grid>
      </Grid>
    </>
  );
};

export default StrategyAssetWeight;
